import utils from '../utils';
import { initializeClickActionFramework } from 'click-action-framework';

const initialize = () => {
  // Bootstrap to the window object.
  if (utils.getWindow()) {
    initializeClickActionFramework();
  }
};

export default {
  initialize
};
