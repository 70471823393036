const SERVICE_DOMAIN = 'https://www.uciservice.com/ds';

export default {
  environmentName: 'Production',
  toolkit: `${SERVICE_DOMAIN}/api/v1/toolkit`,
  resourceEndpoint: `${SERVICE_DOMAIN}/api/v1/resource`,
  uciservice: 'https://www.uciservice.com',
  targetingService: '/targeting-service/v3/adinfo',
  logging: {
    collector: '/sponsoredcontent/events'
  }
};
