/**
 * @module TargetingAdinfo
 */
import config from '../config';
import querystring from 'query-string';

import utils from '../utils/';
import getLogger from '../utils/logger';

import privacy from '../privacy/framework';

const logger = getLogger('adinfo');

// Internal variables with default values (set dynamically during `initialize()`)
let siteId = '0';
let pageName = '';

// Internal getters used for unit tests only
const _getSiteId = () => `${siteId}`;
const _getPageName = () => pageName;

const TARGETING_ENDPOINT = `${config.targetingService}`;

const requestTargeting = (endpoint, qs) => utils.promiseFetch(`${endpoint}?${qs}`,
  {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    mode: 'cors'
  })
  .then((resp) => {
    logger.perf('AdInfo Request', { mark: 'AdInfoRequest', url: `${endpoint}?${qs}` });
    if (resp && resp.errors && resp.errors.length > 0) {
      throw new Error(`Could not retrieve targeting information from ${endpoint}?${qs}`);
    }

    return resp.json();
  });

/**
 * TODO: Move this validation to adinfo-data-modifier
 *
 * Method to fix 'gaia' location values (origin/dest) on targeting object and
 * format star rating values to be an array of strings for adinfo request
 *  - If `targetingPushParams.origin` is not a number (or string of numbers) and locResolver=gaia, it will be removed
 *  - If `targetingPushParams.dest` is not a number (or string of numbers) and locResolver=gaia, it will be removed
 *  - Additional signals needed for MSFT-12999 to be cleaned up later
 * @param {Object} targetingParams
 * @returns {Object} targetingParams
 */
const reviseTargetingParams = (targetingParams) => {
  if (targetingParams?.locResolver?.toString()?.toLowerCase() === 'gaia') {
    if (targetingParams.hasOwnProperty('dest') && isNaN(Number(targetingParams.dest))) {
      logger.warn(`Location resolver specified 'gaia' but destination is not GAIA regionId: ${targetingParams.dest}`);
      delete targetingParams.dest;
    }
    if (targetingParams.hasOwnProperty('origin') && isNaN(Number(targetingParams.origin))) {
      logger.warn(`Location resolver specified 'gaia' but origin is not GAIA regionId: ${targetingParams.origin}`);
      delete targetingParams.origin;
    }
  }

  if (targetingParams.hasOwnProperty('sr')) {
    const starRating = targetingParams.sr.split(',');
    targetingParams.starRating = starRating;
    delete targetingParams.sr;
  }

  return targetingParams;
};

/**
 * Retrieve targeting from the backend adinfo endpoint (managed by Data Platform):
 *  1. Create a new object with default content for all the keys which the adinfo endpoint requires
 *  2. Apply the validated targetingPushParams to that object to override the defaults
 *  3. Use this params object to call the targeting endpoint
 *
 * @param {object} targetingPushParams - Search context parameters from the current page which get pushed to the adinfo endpoint.
 * @returns {Promise} - A Promise that resolves with the JSON response from adinfo. Adinfo will prioritize page-pushed parameters and then use stored targeting to fill in the rest of user targeting
 */
const fetchTargeting = (targetingPushParams = {}) => {
  const uuid = utils.buildUUID();

  if (uuid === '') {
    return Promise.reject(Error('UUID is required, but was not found in the DUAID, MC1, GUID, or hav cookies.'));
  }

  const params = Object.assign({
    siteId,
    pageName,
    uuid,
    app: false,
    source: 'ads-loader-script',
    canTrack: privacy.getPrivacyByKey('canTrack') || false,
    ppid: utils.getCookie('eg_ppid') || '',
    _: Date.now()
  }, reviseTargetingParams(targetingPushParams));

  window?.performance?.mark('AdInfoRequest');

  return requestTargeting(TARGETING_ENDPOINT, querystring.stringify(params));
};

/**
 * Dynamically set siteId and pageName from delivery-service response when a new page is initialized.
 * @param {Object} response - The toolkit response from a delivery-service request.
 */
const initialize = (response = {}) => {
  siteId = `${response.params && response.params.siteId || '0'}`;
  pageName = response.params && response.params.pageId || '';
};

export default {
  fetchTargeting,
  reviseTargetingParams,
  initialize,
  _getSiteId,
  _getPageName
};
