import utils from './utils';
import config from './config';
import experimentsFramework from './experiments';
import targetingFramework from './targeting';
import privacy from './privacy/framework';
import getLogger from './utils/logger';
import { logEvents } from './utils/logger/logEvents';
import ExperimentsHandler from './utils/experimentsHandler';

const logger = getLogger('ads-delivery-interface');
// Module scoped cache.
let cache = {};

/**
 * Dispatches the mesoFallback event with details
 * @param {String} type - Short description of the failure reason
 * @param {*} info - optional extra info about the failure
 */
const onFetchAdsFailure = (type, info) => {
  if (window.dispatchEvent) {
    window.dispatchEvent(new CustomEvent(
      'mesoFallback',
      {
        detail: {
          code: 'FETCH-ADS-FAIL',
          isTimeout: false,
          src: 'ads-delivery-interface.onFetchAdsFailure()',
          type,
          info
        }
      }
    ));
  }
};

/**
 * Loads resource files to the DOM.
 * @param {Object} resources
 * @returns {Promise}
 */
const loadResources = (resources = {}) => {
  const resourcelist = [];
  const canTrack = privacy.getPrivacyByKey('canTrack') || false;
  const bp = utils.getCurrentBreakpoint();
  publishCanTrackEvent(canTrack);

  if (!(resources && resources.script)) {
    return Promise.all(resourcelist);
  }

  // Step through resources and add script loads as promises.
  for (const script of resources.script) {
    const gdprCompliant = script.gdprCompliant || false;
    const canDownload = (canTrack || gdprCompliant);
    const passesBpCheck =
      bp === null || // breakpoints framework isn't running
      !Array.isArray(script.breakpoints) || // breakpoints key isn't defined (all bps included)
      script.breakpoints.includes(bp); // current breakpoint is in the breakpoints array

    if (script.src && canDownload && passesBpCheck) {
      // Attributes are stored as key/value properties.
      if (script.variantsEnabled && script.variantsEnabled.length) {
        const experimentsToCheck = script.variantsEnabled.map((experiments) => experiments.split('.')[0]);
        const experimentHandlerPromise = new ExperimentsHandler(experimentsToCheck).wait()
          .then((experiments) => {
            const isScriptEnabled = script.variantsEnabled
              .some((experimentToFilterOn) => {
                const [experiment, bucket] = experimentToFilterOn.split('.');

                return experiments.hasOwnProperty(experiment) &&
                  experiments[experiment] !== null &&
                  parseInt(experiments[experiment], 10) === parseInt(bucket, 10);
              });

            if (isScriptEnabled) {
              return utils.promiseScript(script.src, script);
            }
          });

        resourcelist.push(experimentHandlerPromise);
      }
      else {
        resourcelist.push(Promise.resolve(utils.promiseScript(script.src, script)));
      }
    }
    else {
      onFetchAdsFailure('LoadResourcesSkipped', {
        src: script.src,
        canTrack,
        canDownload,
        passesBpCheck
      });
    }
  }

  // Pass back a promise that resolves when all scripts have been fulfilled or rejected.
  return Promise.all(resourcelist)
    .then((resources) => Promise.all(resources.filter((resourcePromise) => resourcePromise)));
};

/**
 * Filters a copy of the cache. Filters the content entry of the cache copy,
 * removing any entries that are configured for a breakpoint other than the
 * current breakpoint.
 * @returns {object} The copy of the cache with content filtered.
 */
const filterAdsByBreakpoint = () => {
  let result;
  try {
    // Deep clone the cache.
    result = JSON.parse(JSON.stringify(cache));
  }
  catch (e) {
    // Something went wrong with JSON parse/stringify, return cache.
    return cache;
  }
  if (Object.keys(cache).length === 0) {
    // Nothing to filter, just return the copy.
    return result;
  }
  const bp = utils.getCurrentBreakpoint();
  if (bp === null) {
    // Breakpoint framework is not running, just return the copy.
    return result;
  }

  // Filters the content section of the response to only include content for the
  // current breakpoint. If breakpoints are not specified for a particular piece
  // of content, it is assumed that the content is available at all breakpoints.
  if (result.config) {
    // Cycle through all products in the config section.
    Object.keys(result.config).forEach((product) => {
      if (Array.isArray(result.config[product].conditions)) {
        // Cycle through all entries for the product.
        result.config[product].conditions.forEach((condition) => {
          const key = condition.uci || condition.slot;
          // If the breakpoints key is defined AND the current breakpoint
          // is not found in the list, remove this entry from the content list.
          if (
            Array.isArray(condition.breakpoints) &&
            !condition.breakpoints.includes(bp)
          ) {
            if (
              result.content &&
              result.content[product] &&
              result.content[product][key]
            ) {
              delete result.content[product][key];
            }
          }
        });
      }
    });
  }

  return result;
};

/**
 * Renders ads to the page by dispatching mesoRender event.
 */
const renderAds = () => {
  if (Object.keys(cache).length === 0) {
    // Render has been called before initialize.
    return;
  }
  const renderDetails = filterAdsByBreakpoint();
  const render = new CustomEvent('mesoRender', {
    detail: renderDetails
  });
  window.dispatchEvent(render);
};

/**
 * Clears the module cache.
 */
const clearCache = () => {
  cache = {};
};

/**
 * Loads ad resources and renders ads. To be called after config data is recieved and frameworks are initilaized.
 * @param {Object} data - switchboard config data
 */
const onDependeciesLoaded = (data) => {
  loadResources(data.resources || {})
    .then((scripts) => {
      let errorCount = 0;
      scripts.forEach((s) => {
        if (s.errInfo) {
          logger.error(logEvents.RESOURCE_LOAD_FAIL, s.errInfo, s.errInfo);
          errorCount += 1;
        }
      });
      if (errorCount > 0) {
        const msg = `Errors occurred loading resources (${errorCount})`;
        logger.warn(msg, {
          adResourcesFailed: scripts.filter((script) => script.errInfo !== undefined).map((script) => script.src),
          adResourcesLoaded: scripts.filter((script) => script.errInfo === undefined).map((script) => script.src),
          methodName: 'loadResources'
        });
      }
      else {
        logger.info('Resources loaded successfully', { adResourcesLoaded: scripts.map((script) => script.src), methodName: 'loadResources' });
      }
      renderAds();
    });
};

/**
 * Fetch config/resources/content/targeting from ads delivery service.
 *
 * @param {object} target - path arguments to fetch against
 * @param {string} target.pageId - page id/name for the current target
 * @param {string} target.siteId - site id of the current target
 * @param {string} target.locale - locale of the current request
 * @param {string} [target.variant] - specify if this is a special case: same id's but logically different pages
 */
const fetchAds = (target) => {
  // We always clear the cache on a new request.
  clearCache();

  // Construct the request path.
  const { pageId, siteId, locale, variant, brand } = target;
  const path = variant === undefined ?
    `${pageId}/${siteId}/${locale}` :
    `${pageId}/${siteId}/${locale}/${variant}`;

  // Construct the query parameters.
  const queryParams = {};

  // If brand is defined, add it to the query parameters.
  if (brand !== undefined) {
    queryParams.brand = brand;
  }

  // TODO: Remove this after switchboard is released 100% to prod.
  // NOTE: Not including unit tests for this since it is planned to be short lived.
  // If switchboard feature flag is set in the cookie, add it to the query parameters.
  const switchboard = utils.getCookie('switchboard');
  if (switchboard !== null) {
    queryParams.switchboard = switchboard;
  }

  const fullUrl = `${config.toolkit}/${path}${utils.queryString(queryParams)}`;

  if (window.performance && window.performance.mark) {
    window.performance.mark('DisplayRequest');
  }

  // Call Delivery Service API
  utils.promiseFetch(fullUrl,
    {
      headers: {
        Accept: 'application/json'
      },
      mode: 'cors'
    })
    .then((resp) => resp.json())
    .then((data) => {
      logger.perf('Display Request', { mark: 'DisplayRequest', url: fullUrl });
      cache = data;
      // Set a timestamp on the current cache data.
      cache.timestamp = Date.now();

      // Check for Switchboard config data and prepare ads for rendering.
      // If config is missing or there's an error, use ad fallbacks and log the issue.
      if (Object.keys(data).length <= 1) { // Check if Switchboard configuration is empty for this Delivery Service API request
        onFetchAdsFailure('AdsConfigurationEmpty');
        logger.warn(logEvents.CONFIG_API_RESPONSE_EMPTY, { url: fullUrl, description: 'The Switchboard configuration is empty for this Delivery Service API request', methodName: 'fetchAds' });
      }
      else if (data.error) { // Error set and returned by Delivery Service API when a non-200 response is received from Switchboard API
        onFetchAdsFailure('ErrorFetchingAdsConfiguration');
        logger.error(logEvents.CONFIG_API_FETCH_ERROR, data.error, { url: fullUrl, description: 'A non-200 response was received from Switchboard API', methodName: 'fetchAds' });
      }
      else { // Happy path
        // Initialize the targeting framework with the target details
        targetingFramework.initialize(data);
        // Initialize the experiments framework with the configured experiments
        experimentsFramework.initialize(data);

        logger.info('API call success', { url: fullUrl, methodName: 'fetchAds' });

        // Verify proper state then load ads
        if (utils.getReadyState() === 'complete' || utils.getReadyState() === 'loaded' || utils.getReadyState() === 'interactive') {
          onDependeciesLoaded(data);
        }
        else {
          window.addEventListener('readystatechange', (event) => {
            if (event.target.readyState === 'interactive') {
              onDependeciesLoaded(data);
            }
          });
        }
      }

      // When the breakpoint changes re-request resources and re-render ads for that breakpoint.
      window.meso.adcontext.observe('ads-delivery-interface',
        (params) => params.includes('breakpoint'),
        () => onDependeciesLoaded(data)
      );
    })
    .catch((error) => {
      onFetchAdsFailure('FetchAdsConfigurationRequestBlockedOrTimeout');
      logger.error(logEvents.CONFIG_API_FETCH_ERROR, error, { url: fullUrl, description: 'FetchAdsConfigurationRequestBlockedOrTimeout', methodName: 'fetchAds' });
    });
};

function publishCanTrackEvent(canTrack) {
  document.dispatchEvent(new CustomEvent('mesoAdFallbackEvent', { detail: { type: 'gdpr', canTrack } }));
}

export default {
  fetchAds,
  renderAds,
  clearCache,
  _loadResources: loadResources
};
