/* eslint-disable no-console */
import config from '../../../config';
import collectorRequestHandler from './logSender';
import utils from '../../index';

export default (mesoNamespace = window.meso) => {
  let autoCallOutTimer;
  let hasUnloadListened;
  let loggingOffset = 0;

  function collectorEventQueueCallback(...data) {
    if (!mesoNamespace.logging || !mesoNamespace.logging.isEventQueue) {
      // eslint-disable-next-line no-console
      console.warn('window.meso.logging is not an event queue');

      return;
    }

    if (hasUnloadListened) {
      sendEventsAsync(data);

      return;
    }

    handleQueuedLogs(data);
  }

  /**
   * Send events with log data to the downstream services for parsing, processing, alerting, and monitoring
   * @param {Object} logData - an array of the log objects sent to the logging framework since the last time logs were sent to the collectors
   * @returns {undefined | Error} returns nothing if the requests to the collector endpoints are successful, but will throw an error if there's any problem
   */
  function sendEventsAsync(logData) {
    const cleanedLogData = cleanLoggingPayload(logData);
    if (cleanedLogData.length > 0) {
      collectorRequestHandler(cleanedLogData)
        .catch((error) => new Error(`Error calling collector endpoints: ${JSON.stringify(error)}`));
    }
    else {
      console.error('Error in sending data to the collector endpoints - no items to send');

      return new Error('Error in sending data to the collector endpoints - no items to send');
    }
  }

  const getOffsetLength = (loggingObject) => loggingObject.executedEvents.length - loggingOffset;

  function getLogArray() {
    if (!(mesoNamespace && mesoNamespace.logging && mesoNamespace.logging.isEventQueue)) {
      console.warn('window.meso.logging is not an event queue');

      return [];
    }

    const loggingArray = mesoNamespace.logging.executedEvents;
    if (loggingArray.length === 0 || loggingOffset >= loggingArray.length) {
      return [];
    }

    const collectorLoggingArray = utils.parseLogs(loggingArray.slice(loggingOffset, loggingArray.length));
    // TODO handle the case of send to Web Collector failed, by revert the offset previous version back.
    // handle the handshake network issue(s) with Web Collector endpoint
    loggingOffset = loggingArray.length;

    return cleanLoggingPayload(collectorLoggingArray);
  }

  function cleanLoggingPayload(rawLogsArray) {
    const cleanedArray = rawLogsArray.filter(Boolean).filter((value) => Object.keys(value).length !== 0)
      .filter((object) => object.logs);

    cleanedArray.filter((object) => !object.timestamp)
      .forEach((object) => {
        object.timestamp = utils.esDateString();
      });

    cleanedArray.filter((object) => !object.epoch)
      .forEach((object) => {
        object.epoch = Date.now();
      });

    cleanedArray.filter((object) => !object.platform)
      .forEach((object) => {
        object.platform = config.logging.platform;
      });

    return cleanedArray;
  }

  function sendEvents() {
    const dataDump = getLogArray();
    if (dataDump.length > 0) {
      setAutoLogCallOutTimer();
      collectorRequestHandler(dataDump)
        .catch(() => {
          collectorRequestHandler(dataDump)
            .catch((err) => {
              console.error('Error in sending data to Web Collector', err);

              return new Error(`Error calling Web Collector endpoint ${JSON.stringify(err)}`);
            });
        });
    }
    else {
      console.error('Error in sending data to Web Collector - no items to send');

      return new Error('Error in sending data to Web Collector - no items to send');
    }
  }

  /**
   * If the user stays on the page for a certain number of seconds, this should still send data to collector
   */
  function setAutoLogCallOutTimer() {
    if (!autoCallOutTimer) {
      autoCallOutTimer = setTimeout(() => {
        autoCallOutTimer = null;
        sendEvents();
      }, config.logging.autoCallout);
    }
  }

  function handleQueuedLogs() {
    if (getOffsetLength(mesoNamespace.logging) > config.logging.maxLogsInQueue) {
      clearTimeout(autoCallOutTimer);
      autoCallOutTimer = null;
      sendEvents();
    }
  }

  function initLogger() {
    if (typeof window !== 'undefined') {
      if (document && typeof document.addEventListener === 'function') {
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'hidden') {
            sendEvents();
          }
        }, { capture: true });

        const terminationEvent = 'onpagehide' in window ? 'pagehide' : 'unload';
        window.addEventListener(terminationEvent, () => {
          hasUnloadListened = true;
          sendEvents();
        }, { capture: true });

        window.addEventListener('load', () => {
          setAutoLogCallOutTimer();
        }, { capture: true });
      }
    }
  }

  return { initLogger, cleanLoggingPayload, collectorEventQueueCallback };
};
