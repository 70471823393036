import config from '../../../config';
import utils from '../..';

const mesoCollectorUrl = config.logging.collector;
const webCollectorUrl = config.logging.performance.endpoint;

const fetchOptions = (data) => ({
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
});

/**
 * Send logs to the event collectors for processing:
 *  1. Web Collector for EGSP
 *  2. meso-event-collector for DataDog
 * @param {JSON | String} data - the logs which have been pushed to the logging queue
 * @returns {Promise} - resolves based on success of API requests
 */
const collectorRequestHandler = (data) => new Promise((resolve, reject) => {
  if (data && data.length > 0) {
    const logs = utils.parseLogs(data); // We have to check the logs bc other code bases sometimes push strings to the logging queue, which aren't accepted
    const nav = utils.getNavigator();

    if (typeof nav === 'undefined' || typeof nav.sendBeacon === 'undefined') {
      resolve(Promise.all(
        utils.promiseFetch(mesoCollectorUrl, fetchOptions(logs)),
        utils.promiseFetch(webCollectorUrl, fetchOptions(logs)).then((resp) => {
          if (resp && resp.errors && resp.errors.length > 0) {
            reject(new Error('The Web Collector responded with errors'));
          }
        }).catch((error) => reject(error))
      ));
    }
    else if (
      nav.sendBeacon(webCollectorUrl, JSON.stringify(logs)) &&
      nav.sendBeacon(mesoCollectorUrl, JSON.stringify(logs))
    ) {
      resolve(true);
    }
    else {
      reject(false);
    }
  }
  else {
    resolve(true);
  }
});

export default collectorRequestHandler;
