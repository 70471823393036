import getLogger from '../utils/logger';
import { logEvents } from '../utils/logger/logEvents';
import utils from '../utils';

const maxQueryStringCharacters = 4096;

/*
Code for express endpoints and other research code can be found at:
https://github.expedia.biz/MediaSolutions/ads-loader-script/pull/371
 */

const logger = getLogger('Beacons');

const validation = Object.freeze({
  URL: 'URL',
  URLMAX: 'URLMAX',
  UNKNOWN: 'UNKNOWN'
});

/**
 * Gets the instance for validation. Currently only URLs types are supported.
 * @param {string} url - url we're validation
 * @param {undefined} data - data we're going to validate
 * @returns {string} - one of the above `validation` types
 * @private
 */
const getInstanceValidation = (url, data) => {
  /*
  The logic behind this is it will return the type of the payload (or 'unknown')
  if not supported.  If the type is supported but the payload is too big for
  Beacon, then it will append a "-max" to the type.
   */
  if (data === undefined) {
    return (maxQueryStringCharacters > url.length) ? validation.URL : validation.URLMAX;
  }

  return validation.UNKNOWN;
};

/**
 * Fallback send Beacon data via Fetch API
 * @param {string} url - url we're validation
 * @param {undefined} data - data we're going to validate
 * @returns {Promise} - if the data was sent successfully
 * @public
 */
const sendBeaconFallback = (url, data) => {
  utils.promiseFetch(url,
    {
        method: 'POST',
        headers: {
          'Accept': 'application/x-www-form-urlencoded',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify(data)
    }).then((resp) => !(resp && resp.errors && resp.errors.length > 0)).catch(() => false);
};

/**
 * Validates and then sends the beacon data
 * @param {string} url - url we're validation
 * @param {undefined} data - data we're going to validate
 * @returns {boolean} - if the data was sent successfully
 * @public
 */
const sendBeacon = (url, data) => {
  switch (getInstanceValidation(url, data)) {
    case validation.UNKNOWN:
      logger.error(logEvents.BEACON_SEND_FAIL,
        new Error(`Unsupported data type being sent to sendBeacon().`),
        { url, data });

      return true;
    case validation.URLMAX:
      logger.error(logEvents.BEACON_SEND_FAIL,
        new Error(`Data is bigger than max size allowed for type 'URL' in sendBeacon().`),
        { url, data });

      return true;
    default:
      // Everything is good, let's send it
      if (navigator.sendBeacon(url, data)) {
        return true;
      }
  }

  // Beacons framework didn't work as expected.  Let's do it the old fashioned way
  return sendBeaconFallback(url, data);
};

export { sendBeacon, sendBeaconFallback };
