function isLoggedUserOnBEX() {
  if (window.utag_data && window.utag_data.loggedUser !== undefined && window.utag_data.loggedUser !== null && window.utag_data.loggedUser !== '') {
    if ((typeof window.utag_data.loggedUser === 'number' && window.utag_data.loggedUser !== -1) ||
        (typeof window.utag_data.loggedUser === 'string' && window.utag_data.loggedUser.trim() !== '' && window.utag_data.loggedUser.toLowerCase() !== 'guest')) {
      return true;
    }
  }

  return false;
}

function isLoggedUserOnHotwire() {
  const utagData = window.utag_data;

  if (utagData && utagData.customerId && parseInt(utagData.customerId, 10) > 0) {
    return true;
  }

  return false;
}

function firePixel() {
  const existingAudiencePixel = document.querySelector('#audience-pixel');
  if (existingAudiencePixel) {
    existingAudiencePixel.remove();
  }

  const axel = Math.random() * 10000000000000;
  const newAudiencePixel = document.createElement('img');
  newAudiencePixel.src = `https://pubads.g.doubleclick.net/activity;dc_iu=/23171577/DFPAudiencePixel;ord="${axel}";dc_seg=907193633?`;
  newAudiencePixel.style = 'width:1px; height:1px; border:0; position: fixed; bottom: 0; left: 0';
  newAudiencePixel.id = 'audience-pixel';
  document.body.appendChild(newAudiencePixel);
}

function triggerPixelIfLoggedIn(isUserLoggedIn) {
  if (isUserLoggedIn || isLoggedUserOnBEX() || isLoggedUserOnHotwire()) {
    firePixel();
  }
}

const fireDFPAudiencePixel = (targetData) => {
  if (targetData.canSell !== undefined && !targetData.canSell) {
    if (document.readyState === 'complete') {
      triggerPixelIfLoggedIn(targetData.isUserLoggedIn);
    }
    else {
      window.addEventListener('load', () => {
        triggerPixelIfLoggedIn(targetData.isUserLoggedIn);
      });
    }
  }
};
export {
  fireDFPAudiencePixel
};
