import utils from '../utils';
import privacy from './framework.js';

const initialize = () => {
  // Bootstrap to the window object.
  if (utils.getWindow()) {
    window.meso = window.meso || {};
    window.meso.privacy = window.meso.privacy || {};
    window.meso.privacy.getAll = privacy.getPrivacy;
    window.meso.privacy.get = privacy.getPrivacyByKey;
  }
};

export default {
  initialize
};
