import platforms from '../../json/platforms.json';
import { createEventQueue } from 'meso-event-queue';
import PlatformBreakpoints from './platform-breakpoints';

function initBreakpoints() {
  // Handles all platform breakpoints.
  const platformBreakpoints = new PlatformBreakpoints(platforms);

  /**
   * Process any events pushed to the event queue.
   * @param {object} payload - The data sent to the event queue.
   */
  const process = (payload) => {
    switch (payload.type) {
      case 'platform':
        platformBreakpoints.setPlatform(payload.platform);
        break;
      default:
    }
  };

  // Make sure that the breakpoints and adcontext global objects are defined.
  if (window) {
    window.meso = window.meso || {};
    window.meso.breakpoints = window.meso.breakpoints || [];
    if (!window.meso.breakpoints.isEventQueue) {
      createEventQueue(process, window.meso.breakpoints, {}, 'breakpoints');
    }
  }
}

initBreakpoints();

export { initBreakpoints };
