/**
 * Targeting Module
 * @module Targeting
 */
import debounce from 'lodash.debounce';

import { validateAdinfoParams } from './adinfo-data-modifier';
import { createPrioritizedEventQueue } from 'meso-event-queue';
import getLogger from './../utils/logger';
import { logEvents } from './../utils/logger/logEvents';
import config from '../config';
import adinfo from './adinfo';
import privacy from '../privacy/framework';
import adsParameter from './ads-targeting-parameter';
import utils from '../utils';

let savedTargeting = {};
let queuedTargeting = {};
let adinfoRequest = null;
let adinfoReqData = {};
let adinfoLastUpdated = 0;
let targetingLastUpdated = 0;

const logger = getLogger('targeting');

/**
 * Filters targeting data from sessionStorage.
 * Values filtered here should only be cached for each session.
 * @param {JSON} obj - targeting object from session storage object
 * @returns {JSON} - Targeting without keys to ignore
 */
const filterStorageData = (obj) => {
  const data = obj || {};
  const toSkip = new Set(['cmptst', 'CMPTST']);

  return Object.keys(data)
    .reduce((acc, key) => {
      if (!toSkip.has(key)) {
        acc[key] = obj[key];
      }

      return acc;
    }, {});
};

/**
 * Retreives targeting data from sessionStorage.
 * @returns {JSON} - A JSON string representation of the targeting data from
 *   session storage. If there is an error, an empty object is returned.
 */
const getStorageData = () => {
  // Get sessionStorage targeting.
  let ss = {};
  try {
    ss = window?.sessionStorage?.getItem(config.meso.storage.targeting);
    ss = filterStorageData(JSON.parse(ss));
  }
  catch (e) {
    logger.error(logEvents.TARGETING_INVALID, e, { description: 'Error reading targeting from session storage', methodName: 'getStorageData' });
    ss = {};
  }

  return ss;
};

/**
 * A Promise wrapper for the getStorageData function.
 * @returns {Promise} - A Promise that resolves with the value returned from
 *   getStorageData function.
 */
const storagePromise = () => Promise.resolve(getStorageData());

/**
 * Saves the passed targeting data to session storage.
 * @param {JSON} data - The data to save to session storage.
 */
const setStorageTargetingData = (data) => {
  window?.sessionStorage?.setItem(
    config.meso.storage.targeting,
    JSON.stringify(data)
  );
};

/**
 * Loads the session targeting from sessionStorage.
 * @returns {Promise} - A Promise of the targeting read from session storage.
 */
const loadTargeting = () => Promise.all([
  Promise.resolve(savedTargeting),
  storagePromise()
]).then((targetingSources) => Object.assign({}, ...targetingSources));

/**
 * Save targeting is executed whenever we want to update the current targeting.
 *   It both updates the modules targeting and saves the results to local storage.
 * @param {JSON} targeting - The targeting data to save.
 */
const saveTargeting = (targeting) => {
  savedTargeting = targeting;
  setStorageTargetingData(targeting);
};

/**
 * All targeting values are required to be strings. This will convert other
 *   data types to a supported string format.
 * @param {any} value - A value to convert.
 * @returns {string} - The value converted to a supported string format.
 */
const formatTargetingValue = (value) => {
  if (value === true) {
    return '1';
  }
  else if (value === false) {
    return '0';
  }
  else if (Array.isArray(value)) {
    return String(value.map((v) => formatTargetingValue(v)));
  }

  return String(value);
};

/**
 * Formats the targeting object's values for DFP/GAM.
 * @param {object} obj - The targeting object to normalize.
 * @returns {object} - The normalized targeting object.
 */
const _normalizeTargeting = (obj) => (
  Object.keys(obj).reduce((acc, key) => {
    acc[key] = formatTargetingValue(obj[key]);

    return acc;
  }, {})
);

/**
 * Sends the targeting event to the window with the updated targeting object.
 * @param {object} targeting - The targeting object to send.
 */
const dispatchTargeting = (targeting) => {
  document.dispatchEvent(
    new CustomEvent('MesoTargeting', {
      detail: {
        type: 'gpt_update',
        // Add a parameter to targeting object to specify that this is from ADS.
        targeting: adsParameter.addAdditionalParameters(targeting),
        adinfoLastUpdated,
        targetingLastUpdated
      }
    })
  );
  document.dispatchEvent(
    new CustomEvent('MesoTargeting', {
      detail: {
        type: 'update',
        // Add a parameter to targeting object to specify that this is from ADS.
        targeting: adsParameter.addAdditionalParameters(targeting),
        adinfoLastUpdated,
        targetingLastUpdated
      }
    })
  );
};

/**
 * Checks a passed targeting object against the current targeting object. If any
 *   values in the passed targeting object do not match the current targeting
 *   object, we know that targeting has changed.
 * @param {object} updates - A new targeting object to check against current.
 * @returns {boolean} - Whether changed values were found in the new targeting
 *   object.
 */
const isNewTargetingValue = (updates) => {
  for (const key in updates) {
    if (updates[key] !== savedTargeting[key]) {
      return true;
    }
  }

  return false;
};

/**
 * The is an temporary fix to validate string to boolean values such as "true"
 * @param {any} value
 */
const getBoolean = (value) => `${value}` === 'true';

/**
 * A Promise function that fetches targeting from adinfo. In the case of an
 * error, an empty targeting object is returned.
 * @param {object} params - Additional parameters to pass to the adinfo fetch.
 * @returns {Promise} - A Promise that resolves with the targeting object from
 *   adinfo.
 */
const fetchAdinfoTargeting = (params = {}) => {
  if (getBoolean(privacy.getPrivacyByKey('canTrack')) && !utils.isSafeframe()) {
    return adinfo.fetchTargeting(params)
      .then((resp) => {
        adinfoLastUpdated = Date.now();

        return resp && resp.targeting || {};
      })
      .catch((e) => {
        logger.error(logEvents.TARGETING_FETCH_ERROR, e, { params, methodName: 'fetchAdinfoTargeting' });
        adinfoLastUpdated = Date.now();

        return {};
      });
  }

  return Promise.resolve({});
};

/**
 * Returns whether or not the adinfo specific data has changed since the last
 * targeting update.
 * @param {object} data - a key value pair of values that affect adinfo targeting
 * @returns {boolean} - Whether or not new data was found since the last request.
 */
const isNewAdinfoValue = (data = {}) =>
  Object.keys(data).some((key) => data[key] !== adinfoReqData[key]);

/**
 * Pulls targeting profile from adinfo endpoint then dispatches to all listeners.
 * Dispatches session storage data if no new targeting data is pushed to the queue.
 * @param {object} data - A targeting object pushed to the queue.
 */
const _updateAndDispatch = (data) => {
  // Accept data from the old `adinfo` key or the new `targeting` key (TODO: remove sending targeting params via `adinfo` key on targeting push in external repos)
  data.targeting = Object.assign({}, data.adinfo, data.targeting);

  // Remove data that the adinfo endpoint cannot accept
  data.targeting = validateAdinfoParams(data.targeting);

  // Only make a request to adinfo if this is the first request or adinfo data has changed.
  if (adinfoRequest === null || isNewAdinfoValue(data.targeting)) {
    // Store the latest adinfo request data in the module cache for comparison to future requests
    adinfoReqData = Object.assign({}, adinfoReqData, data.targeting);

    const targetingPushParams = data.targeting || {};
    adinfoRequest = fetchAdinfoTargeting(Object.assign({}, targetingPushParams));
  }
  else {
    adinfoRequest = Promise.resolve({});
  }

  adinfoRequest
    .then((targeting) => _normalizeTargeting(targeting)) // normalize values into types that DFP supports
    .then((targeting) => {
      // Save and dispatch targeting when new targeting value is found
      if (isNewTargetingValue(targeting)) {
        targetingLastUpdated = Date.now();
        saveTargeting(targeting);
        dispatchTargeting(targeting); // send an update to registered components with new targeting data
      }
      else {
        // If no new targeting data is found, dispatch the existing session targeting
        dispatchTargeting(savedTargeting);
      }
    })
    .catch((e) => {
      logger.error(logEvents.TARGETING_UPDATE_ERROR, e, { methodName: '_updateAndDispatch' });
    });
};

/**
 * Debounces the update call so that we process the entire queue at once.
 */
const debouncedUpdate = debounce(() => {
  _updateAndDispatch(queuedTargeting);
  queuedTargeting = {};
}, 0, { leading: false, trailing: true });

/**
 * Assign queued targeting into the current queuedTargeting object to override previously pushed values
 * and then debounce the update call.
 * @param {object} data - The targeting data that was pushed onto the queue.
 */
const update = (data) => {
  queuedTargeting = Object.assign(queuedTargeting, data);
  debouncedUpdate();
};

/**
 * Sends the 'ready' Custom Event to the window with the timestamp of the last updated cache
 */
const debouncedDispatchReady = debounce(() => {
  // Only fire Custom Event after adinfo has returned and the targeting cache has been updated
  if (targetingLastUpdated > 0) {
    window.dispatchEvent(
      new CustomEvent('MesoTargeting', {
        detail: {
          type: 'ready',
          timestamp: targetingLastUpdated
        }
      })
    );
  }
}, 0, { leading: false, trailing: true });

/**
 * Log the 'register' event and call debounced dispatch
 * @param {object} data
 */
const register = (data) => {
  logger.debug('Register event received in the targeting framework.', { requestingModule: data.target, methodName: 'register' });

  debouncedDispatchReady();
};

/**
 * Reset targeting timestamp when the event type clear was pushed to the queue
 */
const resetTargetingFramework = () => {
  savedTargeting = {};
  queuedTargeting = {};
  adinfoRequest = null;
  adinfoReqData = {};
  adinfoLastUpdated = 0;
  targetingLastUpdated = 0;
};

/**
 * Call the specific event type call back
 * default is update targeting for backward compatiblity
 * @param {object} data - The data that was pushed onto the queue.
 */
const queueProcess = (data = {}) => {
  if (data !== null && typeof data === 'object') {
    switch (data.type) {
      case 'clear':
        resetTargetingFramework();
        break;
      case 'register':
        register(data);
        break;
      case 'update':
        update(data);
        break;
      default:
        update(data);
    }
  }
};

/**
 * Retrieves a copy of the current targeting cache
 * @param {string} [format='gam'] - The format for the response (we currently only support 'gam')
 * @return {Object} - Targeting key-value pairs
 */
const _getSavedTargeting = (format = 'gam') => {
  if (format !== 'gam') {
    throw Error(`We currently only support 'gam' as the format.`);
  }

  return Object.assign({}, savedTargeting);
};

/**
 * Formats each targeting event for proper type and 'targeting' key
 */
const formatTargetingEvents = () => {
  window.meso.targeting.forEach((currEvent) => {
    // For backwards compatibility, make sure that each item in the queue has a type.
    if (currEvent !== null && typeof currEvent === 'object' && !currEvent.type) {
      currEvent.type = 'update'; // Default to update
    }

    if (currEvent.type === 'register' && !currEvent.hasOwnProperty('target')) {
      // If 'targeting' key is absent, log a warning
      logger.info(logEvents.TARGETING_EVENT_INVALID, {
        currEvent: Object.assign({}, currEvent),
        description: `'target' key is absent from the targeting 'register' payload.`,
        methodName: 'formatTargetingEvents'
      });
    }

    if (currEvent.type === 'update') {
      if (currEvent.hasOwnProperty('adinfo')) {
        logger.warn(logEvents.TARGETING_KEY_DEPRECATED, {
          currEvent: Object.assign({}, currEvent),
          description: `deprecated 'adinfo' key is present in the targeting payload.`,
          methodName: 'formatTargetingEvents'
        });
      }
      if (!currEvent.hasOwnProperty('targeting')) {
        // If 'targeting' key is absent, log a warning and set 'targeting' as empty object
        logger.warn(logEvents.TARGETING_KEY_MISSING, {
          currEvent: Object.assign({}, currEvent),
          description: `'targeting' key is absent from the targeting payload.`,
          methodName: 'formatTargetingEvents'
        });
        currEvent.targeting = {};
      }
    }
  });
};

/**
 * Initializes the targeting and adinfo modules.
 * Register an event queue with the window object so that other code can push
 * targeting data to us.
 * @param {Object} response - The response returned from an ADS request.
 */
const initialize = (response) => {
  window.meso = window.meso || {};
  window.meso.targeting = window.meso.targeting || [];

  if (window.meso.targeting.isEventQueue) {
    return;
  }

  // Some logic will be determined by which adinfo endpoint version we are using. So before the
  // event queue is created and executed to call adinfo, we need to do the following:
  // 1. Dynamically set the targeting endpoint
  adinfo.initialize(response);

  // Setup params for the prioritized event queue
  const priority = ['clear', 'register', 'update'];
  formatTargetingEvents();

  // Now the targeting framework is setup to process the event queue
  window.meso.targeting = createPrioritizedEventQueue(queueProcess, window.meso.targeting, priority, {});

  // Attach the getter after the framework has been turned into an event queu to prevent overwriting
  window.meso.targeting.get = _getSavedTargeting;

  /**
   * AUTORUN
   * The below functionality runs whenever this module is parsed by the browser.
   */
  // Add a listener to send targeting data out when new components register.
  document.addEventListener('MesoTargeting', (e) => {
    const type = e && e.detail && e.detail.type;
    if (type === 'register') {
      loadTargeting().then((targeting) => {
        dispatchTargeting(targeting);
      });
    }
  });

  /**
   * Read targeting from storage and send that out immediately (for products
   * that accept page behind targeting.
   */
  loadTargeting().then((targeting) => {
    dispatchTargeting(targeting);
  });
};

export default {
  initialize,
  _updateAndDispatch,
  _getAdinfoReqData: () => adinfoReqData,
  _getSavedTargeting,
  _setTargetingLastUpdated: (val) => {
    targetingLastUpdated = val;
  },
  _normalizeTargeting
};
