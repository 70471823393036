import { createEventQueue } from 'meso-event-queue';
import collectorEventQueueCallback from '../utils/logger/handlers/index';
import utils from '../utils/index';
let mesoLogger = null;

// Represents our base logging interface, used for validation.
const loggerInterface = {
  info: {},
  warn: {},
  error: {},
  trackEvent: {},
  trackAction: {}
};

/**
 * Function executed for each event in the logging event queue.
 * @param {object} data - The data passed in as the event.
 */
const log = (data) => {
  if (mesoLogger === null) {
    return;
  }
  if (data && data.type) {
    switch (data.type) {
      case 'info':
        mesoLogger.info(data.message || '', data.extra || undefined);
        break;
      case 'warn':
        mesoLogger.warn(data.message || '', data.extra || undefined);
        break;
      case 'error':
        mesoLogger.error(data.message || '', data.extra || undefined);
        break;
      case 'track-event':
        mesoLogger.trackEvent(data.name, data.vars, data.events);
        break;
      case 'track-action':
        mesoLogger.trackAction(data.name, data.vars, data.events);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(data.message);
        break;
    }
  }
};

/**
 * Checks the passed logger interface for the expected methods. Posts warnings
 * to the console if the interface is missing a method.
 * @param {object} logger - The logging interface to check.
 */
const validateLoggerInterface = (logger) => {
  Object.keys(loggerInterface).forEach((action) => {
    if (!(logger && logger[action] && typeof logger[action] === 'function')) {
      // eslint-disable-next-line no-console
      console.warn(`Missing ${action} method on passed logger interface.`);
    }
  });
};

/**
 * wrapper callbacks, that should wrap up passed callbacks together
 * @param {...functions} callbacks - callback the should be called on the event.
 * @return {function} return a function that wrap up the set of passed callbacks call.
 */
const wrapCallbacks = (...callbacks) => (data) => (callbacks.forEach((callback) => callback(data)));

/**
 * Initializes and assigns the logging interface to use for this logging module.
 * Assigning the interface will also initialize and process all messages in the
 * logging queue.
 * @param {object} logger - The logging interface to use for logging.
 */
const initialize = (logger) => {
  validateLoggerInterface(logger);
  // Assign this logger to the module scope.
  // It is allowed to overwrite a previously assigned logger.
  mesoLogger = logger;

  // Create the logging queue and process all events.
  window.meso = window.meso || {};
  window.meso.logging = window.meso.logging || [];
  if (!window.meso.logging.isEventQueue) {
    const parsedEntries = utils.parseLogs(window.meso.logging);
    window.meso.logging = createEventQueue(wrapCallbacks(log, collectorEventQueueCallback), parsedEntries);
  }
};

export default {
  initialize
};
