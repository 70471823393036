/**
 * @class ExperimentsFramework
 */
import throttle from 'lodash.throttle';
import { default as environmentConfig } from './../config';
import utils from '../utils';
import getLogger from '../utils/logger';
import { logEvents } from '../utils/logger/logEvents';

const logger = getLogger('parseOverrideExperiments');

const parseOverrideExperiments = () => {
  try {
    const parsed = {};
    const mesoOverrideCookie = utils.getCookie('meov');
    const experiments = mesoOverrideCookie === null ? [] : mesoOverrideCookie.split(',');

    experiments.forEach((experiment) => {
      const parts = experiment.split('.');
      parsed[parts[0]] = parts[1];
    });

    return parsed;
  }
  catch (err) {
    return {};
  }
};

export default class ExperimentsFramework {
  constructor(config, target) {
    // Configuration is required for the Experiments Framework.
    this._config = config;
    // Set the target.
    this._target = target;
    // Experiments Object: { experiment_name: bucket_value, ... }
    this._cache = {};

    // Throttle function for the initialize experiments custom event.
    this._throttledInitializeExperiments = throttle(
      this._initializeExperiments.bind(this),
      environmentConfig.experiments.throttle,
      { leading: true, trailing: true }
    );
    // Throttle function for the update experiments custom event.
    this._throttledUpdateExperiments = throttle(
      this._updateExperiments.bind(this),
      environmentConfig.experiments.throttle,
      { leading: true, trailing: true }
    );

    // Add a listener to the mesoExperiments event to send Experiments Object
    // to newly registered listeners.
    window.addEventListener('mesoExperiments', (e) => {
      if (e && e.detail) {
        switch (e.detail.type) {
          case 'register':
            this._throttledInitializeExperiments();
            break;
          default:
        }
      }
    });
  }

  // Expose initialize call so that it can be called after the first queue
  // is processed.
  initialize() {
    this._initializeExperiments();
  }

  // Dispatches an event to the window when new components register.
  _initializeExperiments() {
    window.dispatchEvent(
      new CustomEvent('mesoExperiments', {
        detail: {
          type: 'initialize',
          experiments: this._cache
        }
      })
    );
  }

  // Dispatches an event to the window when experiments update.
  _updateExperiments() {
    window.dispatchEvent(
      new CustomEvent('mesoExperiments', {
        detail: {
          type: 'update',
          experiments: this._cache
        }
      })
    );
  }

  // Maps an external experiment id to an internal experiment name.
  // @param {array} experiments - List of experiments to check.
  //   Note: This array is mutated by the method.
  _resolveIds(experiments = []) {
    const keyIdUpdated = [];
    Object.keys(experiments).forEach((key) => {
      const mapped = this._config.filter((configuredExperiment) => configuredExperiment.id === key);
      if (mapped.length > 0) {
        // if experiment ID is found, change to experiment NAME
        const configuredExperiment = mapped.shift();
        keyIdUpdated.push(configuredExperiment);
        experiments[configuredExperiment.name] = experiments[key];
        delete experiments[key];
      }
    });
    if (keyIdUpdated.length) {
      logger.warn(logEvents.EXPERIMENTS_INVALID, {
        description: `Experiment ID provided instead of Name: keyIdUpdated=${JSON.stringify(keyIdUpdated)}, config=${JSON.stringify(this._config)}`,
        methodName: '_resolveIds',
        keyIdUpdated,
        config: this._config
      });
    }
  }

  // Removes any experiments that have not been specified in the config.
  // @param {array} experiments - List of experiments to check.
  //  Note: This array is mutated by the method.
  _removeUnconfigured(experiments = {}) {
    const keysConfigured = this._config.map((item) => item.name);
    const keysResolved = [];
    Object.keys(experiments).forEach((key) => {
      if (keysConfigured.includes(key)) {
        keysResolved.push(key);
      }
      else {
        delete experiments[key];
      }
    });
    if (keysConfigured.length !== Object.keys(experiments).length) {
      logger.warn(logEvents.EXPERIMENTS_INVALID, {
        description: `Experiments are configured, but some were not found: keysConfigured=${JSON.stringify(keysConfigured)}, keysResolved=${JSON.stringify(keysResolved)}, config=${JSON.stringify(this._config)}`,
        methodName: '_removeUnconfigured',
        keysConfigured,
        keysResolved,
        config: this._config
      });
    }
  }

  // Removes child experiments whose parents evaluated to 0.
  // @param {array} experiments - List of experiments to check.
  //  Note: This array is mutated by the method.
  _removeInvalidChildExperiments(experiments = []) {
    const childExperimentsRemoved = [];
    const childExperiments = this._config.filter((config) => config.parent !== undefined);
    Object.keys(experiments).forEach((key) => {
      const mapped = childExperiments.filter((child) => child.name === key);
      if (mapped.length > 0) {
        // Log a warning if multiple entries are found for key. We should only
        // have one entry per target.
        if (mapped.length > 1) {
          logger.warn(logEvents.EXPERIMENTS_INVALID, {
            description: `Multiple entries found for key in config: key=${key}, config=${JSON.stringify(this._config)}`,
            methodName: '_removeInvalidChildExperiments',
            config: this._config
          });
        }
        const parentBucket = experiments[mapped.shift().parent] || 0;
        if (parentBucket === 0 || parentBucket === '0') {
          childExperimentsRemoved.push(key);
          delete experiments[key];
        }
      }
    });
    if (childExperiments.length) {
      const childExperimentsFound = childExperiments.map((item) => item.name);
      logger.info(logEvents.EXPERIMENTS_INVALID, {
        description: `Remove child experiments whose parents evaluated to 0: childExperimentsFound=${JSON.stringify(childExperimentsFound)}, childExperimentsRemoved=${JSON.stringify(childExperimentsRemoved)}, config=${JSON.stringify(this._config)}`,
        methodName: '_removeInvalidChildExperiments',
        childExperimentsFound,
        childExperimentsRemoved,
        config: this._config
      });
    }
  }

  // Add experiments to the Experiment Object.
  // @param {object} experiments - { experiment_name: bucket_value, ... }
  update(experiments) {
    try {
      // Refresh what is in overrides on each update.
      const overrides = parseOverrideExperiments();
      // Converts external ids to internal name mappings.
      this._resolveIds(experiments);
      // Removes experiments that are not in the config.
      this._removeUnconfigured(experiments);
      // Remove children with a parent that has evaluated to control (0).
      this._removeInvalidChildExperiments(experiments);
      // Validate the experiments before we insert them to the cache.
      if (this.validate(experiments)) {
        // Priority Order: Overrides, new experiments, old Experiments Object
        this._cache = Object.assign(this._cache, experiments, overrides);
      }
      this._throttledUpdateExperiments();
    }
    catch (error) {
      logger.error(logEvents.EXPERIMENTS_UPDATE_FAILED, error);
    }
  }

  // Set all configured experiments to control state.
  control() {
    this._cache = {};
    const control = {};
    this._config.forEach((experiment) => {
      control[experiment.name] = 0;
    });
    // Calling update preserves overrides, removes unconfigured, and removes
    // invalid child experiments.
    this.update(control);
  }

  // Empty the Experiments Object of all experiments.
  clear() {
    this._cache = {};
    this.update({});
  }

  // Get the content of the current Experiments Object.
  // @returns {object} experiments - { experiment_name: bucket_value, ... }
  get experiments() {
    return this._cache;
  }

  // Get the names of experiments configured for the Experiments framework.
  get configuredExperimentNames() {
    return this._config.map((condition) => condition.name);
  }

  // Get the names of overrides configured.
  get configuredOverrideNames() {
    return Object.keys(parseOverrideExperiments());
  }

  // Validates a passed value to determine if it is in the correct form to
  // merge into the Experiments Object. The value should be an object that has
  // keys mapped to string/number values.
  // @param value - Any value of any type.
  // @returns {boolean} valid
  validate(value) {
    if (utils.isObject(value)) {
      return Object.keys(value).every((key) =>
        typeof value[key] === 'number' ||
        typeof value[key] === 'string'
      );
    }

    return false;
  }
}
