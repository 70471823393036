import PromiseWrapper from './promiseWrapper';

const _TIMEOUT = 500; // Default timeout for the handler.
let _cache = {}; // Current Experiment Object.
let _handlers = [];

/**
 * Adds an ExperimentsHandler to the current _handlers list.
 * @param {ExperimentsHandler} handler - A reference to the ExperimentsHandler
 *   to add.
 */
const _registerExperimentsHandler = (handler) => {
  if (!handler.checkExperiments(_cache)) {
    _handlers.push(handler);
  }
};

/**
 * Callback for the mesoExperiments custom event. When a change occurs in the
 *  Experiments Object, attempt to resolve all of the currently awaiting
 *  ExperimentsHandlers. Removes ExperimentsHandlers that have resolved.
 * @private
 * @param {Event} e - The event object returned from the custom event.
 */
const _handleEvents = (e) => {
  if (e && e.detail) {
    if (e.detail.type === 'initialize' || e.detail.type === 'update') {
      if (e.detail.experiments) {
        _cache = Object.assign({}, _cache, e.detail.experiments);
        // Filter removes handlers that have finished resolving their experiments.
        _handlers = _handlers.filter((handler) => !handler.checkExperiments(_cache));
      }
    }
  }
};

/**
 * Register the listeners for the Experiments Framework.
 */
if (window && typeof window.addEventListener === 'function') {
  window.addEventListener('mesoExperiments', _handleEvents);
}
if (window && typeof window.dispatchEvent === 'function') {
  window.dispatchEvent(
    new CustomEvent('mesoExperiments', {
      detail: {
        type: 'register'
      }
    })
  );
}

/**
 * Helper class for requesting experiments from the Experiments Framework.
 * @class
 */
class ExperimentsHandler {
  /**
   * @param {string|array} experiments - A list of requested experiments
   */
  constructor(experiments = []) {
    // Requested experiments for this handler.
    this._requested = Array.isArray(experiments) ? experiments : [experiments];
    // Experiments configured for the target.
    this._configured = this._buildExperimentConfig();
    // Experiments to resolve (requested minus those not configured)
    this._resolvable = this._resolvableExperiments();
    this._TIMEOUT = _TIMEOUT;
    this._completePromise = PromiseWrapper.defer();
  }

  /**
   * Checks the current cache for all of the resolvable experiments.
   * @param {object} experiments - An experiments object containing the names of
   *  evaluated experiments mapped to bucket values.
   * @returns {boolean} true if all required experiments are in the cache, false otherwise
   */
  checkExperiments(experiments) {
    const resolved = this._resolvable.length > 0 ?
      this._resolvable.every((name) => experiments.hasOwnProperty(name)) :
      true;
    if (resolved) {
      this._completePromise.resolve(this.getBuckets());
    }

    return resolved;
  }

  /**
   * Validates experiments against the experiments currently configured. Logs
   * warnings for experiments not configured and removes them from the
   * requested list.
   * @returns {string[]} an array of experiment names to resolve
   * @private
   */
  _resolvableExperiments() {
    // Split the requested experiments array into resolvable (found in the
    //  target config) and missing (not found in the target config).
    const { resolvable } = this._requested.reduce((acc, name) => {
      if (this._configured.includes(name)) {
        acc.resolvable.push(name);
      }

      return acc;
    }, { resolvable: [] });

    return resolvable;
  }

  /**
   * Builds the current experiment configuration based on the experiment config
   * from ADS (Ads Delivery System) and overrides.
   * @returns {string[]} And array of strings specifying the configured
   *  experiment names.
   * @private
   */
  _buildExperimentConfig() {
    // Read in the experiments configured by ADS (Ads Delivery System)
    let configuredExperiments = window &&
      window.meso &&
      window.meso.experiments &&
      window.meso.experiments.configuredExperimentNames ||
      [];
    // Make sure that configuredExperiments is an array.
    if (!Array.isArray(configuredExperiments)) {
      configuredExperiments = [];
    }

    // Read in the configured override experiments.
    let configuredOverrides = window &&
      window.meso &&
      window.meso.experiments &&
      window.meso.experiments.configuredOverrideNames ||
      [];
    // Make sure that configuredOverrides is an array.
    if (!Array.isArray(configuredOverrides)) {
      configuredOverrides = [];
    }

    // Combine the two configured lists. Overrides takes prirotiy.
    return [...configuredExperiments, ...configuredOverrides];
  }

  /**
   * Returns the bucket values for the requested experiments.
   * @returns {object} Experiments Object containing the names/buckets of the
   *  requested experiments. Experiments not found will be returned with a
   *  bucket value of null.
   */
  getBuckets() {
    return this._requested.reduce((acc, name) => {
      if (this._resolvable.includes(name)) {
        // Experiment was configured for this target.
        acc[name] = _cache.hasOwnProperty(name) ? _cache[name] : null;
      }
      else {
        // Experiment is not configured for this target, set bucket to null.
        acc[name] = null;
      }

      return acc;
    }, {});
  }

  /**
   * Returns wether or not the requested experiment exists in the config.
   * @param {string} name - The name of the experiment to get the bucket of.
   * @returns {boolean} true if the experiment is in the config, false otherwise.
   */
  isExperimentConfigured(name) {
    return this._configured.includes(name);
  }

  /**
   * Wait for required experiments from the Experiments Framework.
   * @returns {Promise} - A Promise that resolves when either all experiments
   *  are found or the tiemout occurs. (Use getBucket to get buckets)
   */
  wait() {
    if (window && window.meso && window.meso.experiments === undefined) {
      return Promise.resolve(this.getBuckets());
    }

    const race = Promise.race(
      [
        // Promise that polls and resolves with the Experiment Object when all
        // required experiments are present.
        this._completePromise.promise,
        // Promise that resolves with resolved experiments if TIMEOUT is exceeded.
        new Promise((resolve) => {
          setTimeout(() => {
            resolve(this.getBuckets());
          }, this._TIMEOUT);
        })
      ]
    );

    // Register this instance with the singleton.
    _registerExperimentsHandler(this);

    return race;
  }

  /**
   * How long to wait for the required experiments from the Experiments Framework.
   * @type {number}
   */
  get timeout() {
    return this._TIMEOUT;
  }

  set timeout(timeout) {
    this._TIMEOUT = timeout;
  }
}

export default ExperimentsHandler;
