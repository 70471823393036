import utils from '../utils';
import config from '../config';

import getLogger from '../utils/logger';
import { logEvents } from '../utils/logger/logEvents';
const logger = getLogger('ppid-loader');

const dispatchPPIDEvent = (ppid, sourced, message) => {
  if (window.dispatchEvent) { // This avoids errors if there is no window object (browserless)
    const distDetail = {
            detail: {
                type: 'ppid',
                ppid,
                sourced,
                message
            }
    };
    window.dispatchEvent(new CustomEvent('mesoPPID', distDetail));
  }
};

const getPpid = async () => {
  const ppidValue = utils.readPPIDCookie(); // eg_ppid cookiename
  const duaid = utils.readDUAIDCookie(); // DUAID cookiename
  const domain = window.location.hostname;

  // PPID was found in the cookie.
  if (ppidValue !== false) {
    dispatchPPIDEvent(ppidValue, true, 'PPID located in cookie');

    return ppidValue;
  }

  // We're checking to see if the DUAID is in the cookie even though it is not needed to retrieve the
  // PPID with the API endpoint, but if it is not present, let's log it and not make the call
  if (duaid === false) {
    const errorMessage = `DUAID cannot be found in cookie or is invalid. DUAID=${duaid}`;
    logger.error(logEvents.DUAID_NOT_FOUND, new Error(errorMessage));
    dispatchPPIDEvent('', false, 'DUAID not found in cookie');

    return '';
  }

  // Build the endpoint for requesting or generating a PPID.
  const endpointDomain = config.travelPixel.endpointDomainOverride ? config.travelPixel.endpointDomainOverride : domain;
  const ppidEndpoint = [config.travelPixel.endpointProtocol, endpointDomain, config.travelPixel.endpointPath, `?duaid=${duaid}`].join('');

  return await utils.promiseFetch(ppidEndpoint,
    {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'app-name': 'ppid-loader-script'
            }
    }).then((response) => {
    if (response && response.errors && response.errors.length > 0) {
      throw new Error(response.errors);
    }

    return response.json();
  })
    .then((json) => {
      const ppid = json?.ppid;
      if (ppid && ppid.match(utils.uuidPattern)) {
        dispatchPPIDEvent(ppid, true, 'PPID retrieve from Travel Pixel Web Service');

        return ppid;
      }

      const errorMessage = `Could not find PPID in response for DUAID=${duaid}, message=${json.message}.`;
      logger.error(logEvents.PPID_NOT_FOUND, new Error(errorMessage));
      dispatchPPIDEvent('', false, 'Unexpected response format when retrieving PPID from Travel Pixel Web Service');

      return '';
    })
    .catch((error) => {
      const errorMessage = `API Error looking up PPID for DUAID=${duaid}, error=${error.message}.`;
      logger.error(logEvents.PPID_NOT_FOUND, new Error(errorMessage));
      dispatchPPIDEvent('', false, 'Experienced API error when retrieving PPID from Travel Pixel Web Service');

      return '';
    });
};

export default { getPpid, dispatchPPIDEvent };
