export const logEvents = {
  CONFIG_API_FETCH_ERROR: {
    message: 'CONFIG_API_FETCH_ERROR',
    priority: 'P0'
  },
  CONFIG_API_RESPONSE_EMPTY: {
    message: 'CONFIG_API_RESPONSE_EMPTY',
    priority: 'P2'
  },
  BREAKPOINTS_ERROR: {
    message: 'BREAKPOINTS_ERROR',
    priority: 'P3'
  },
  EXPERIMENTS_INVALID: {
    message: 'EXPERIMENTS_INVALID',
    priority: 'P3'
  },
  EXPERIMENTS_UPDATE_FAILED: {
    message: 'EXPERIMENTS_UPDATE_FAILED',
    priority: 'P2'
  },
  EMAIL_FOUND_IN_URL: {
    message: 'EMAIL_FOUND_IN_URL',
    priority: 'P2'
  },
  TARGETING_FETCH_ERROR: {
    message: 'TARGETING_FETCH_ERROR',
    priority: 'P1'
  },
  TARGETING_UPDATE_ERROR: {
    message: 'TARGETING_UPDATE_ERROR',
    priority: 'P2'
  },
  TARGETING_INVALID: {
    message: 'TARGETING_INVALID',
    priority: 'P1'
  },
  TARGETING_VALUE_EMPTY: {
    message: 'TARGETING_VALUE_EMPTY',
    priority: 'P3'
  },
  TARGETING_KEY_MISSING: {
    message: 'TARGETING_KEY_MISSING',
    priority: 'P3'
  },
  TARGETING_KEY_DEPRECATED: {
    message: 'TARGETING_KEY_DEPRECATED',
    priority: 'P3'
  },
  TARGETING_EVENT_INVALID: {
    message: 'TARGETING_EVENT_INVALID',
    priority: 'P3'
  },
  RESOURCE_LOAD_FAIL: {
    message: 'RESOURCE_LOAD_FAIL',
    priority: 'P3'
  },
  INITIALIZE_EVENT_INVALID: {
    message: 'INITIALIZE_EVENT_INVALID',
    priority: 'P3'
  },
  BEACON_SEND_FAIL: {
    message: 'BEACON_SEND_FAIL',
    priority: 'P3'
  },
  PPID_NOT_FOUND: {
    message: 'PPID_NOT_FOUND',
    priority: 'P3'
  },
  DUAID_NOT_FOUND: {
    message: 'DUAID_NOT_FOUND',
    priority: 'P3'
  },
  NON_GAIA_LOCRESOLVER: {
    message: 'NON_GAIA_LOCRESOLVER',
    priority: 'P3'
  },
  AD_CLICK: {
    message: 'AD_CLICK',
    priority: 'P3'
  },
  ADBLOCK_EXPERIMENT: {
    message: 'ADBLOCK_EXPERIMENT',
    priority: 'P5'
  },
  ADBLOCK_ENABLED: {
    message: 'ADBLOCK_ENABLED',
    priority: 'P5'
  },
  ADBLOCK_ACCEPTABLE_ADS: {
    message: 'ADBLOCK_ACCEPTABLE_ADS',
    priority: 'P5'
  },
  TRANSPARENCY_PUBLISH_REQUEST_PASSED: {
    message: 'TRANSPARENCY_PUBLISH_REQUEST_PASSED',
    priority: 'P5'
  },
  TRANSPARENCY_PUBLISH_REQUEST_FAILED: {
    message: 'TRANSPARENCY_PUBLISH_REQUEST_FAILED',
    priority: 'P1'
  },
  TRANSPARENCY_PUBLISH_CALLED_WITH_NO_PAYLOAD: {
    message: 'TRANSPARENCY_PUBLISH_CALLED_WITH_NO_PAYLOAD',
    priority: 'P1'
  }
};
