import utils from '../../utils';

export const setAdblockStorage = (adblock = {}) => {
  // Array to hold each part of the cookie before composing them to the value.
  const parts = [];

  // check and apply the adblock enabled state
  switch (adblock.enabled) {
    case true:
      parts.push('e=1');
      // handle acceptable ads detection
      if (adblock.acceptableads === true) {
        parts.push('aaa=1');
      }
      else {
        parts.push('aaa=0');
      }
      break;
    case false:
      parts.push('e=0');
      break;
    default:
    // Do nothing here, the state of adblock enablement is unknown
  }

  // compose the cookie value
  const cookieValue = parts.join('|').toString();

  // set the cookie if there is a value
  if (cookieValue.length > 0) {
    utils.setCookie('eg_adblock', cookieValue, 7, false);
    window.localStorage.setItem('eg_adblock', cookieValue);
  }
};
