import { logEvents } from '../utils/logger/logEvents';
import getLogger from '../utils/logger';

const logger = getLogger('adinfo');

/**
 * Checks for typeof string
 * @param {string} val
 * @returns {boolean}
 */
const isStr = (val) => typeof val === 'string';

/**
 *  Checks for typeof string with length greater than 0
 * @param {string} val
 * @returns {boolean}
 */
const isNonEmptyStr = (val) => typeof val === 'string' && val.length > 0;

/**
 * Checks if a value is a non-empty array of non-empty strings.
 * @param {any} val - The value to check.
 * @returns {boolean} - Returns true if the value is a non-empty array and all its elements are non-empty strings, false otherwise.
 */
const isNonEmptyStrArray = (val) => Array.isArray(val) && val.length > 0 && val.every((item) => isNonEmptyStr(item));

/**
 * Checks for a non-negative number
 * @param {number} val
 * @returns {boolean}
 */
const isNonnegativeNumber = (val) => typeof val === 'number' && val >= 0;

// Object of all allowed targeting v2 key/value combinations
const v2ParameterChecks = Object.freeze({
    uuid: isNonEmptyStr,
    siteId: (val) => isNonEmptyStr(val) || isNonnegativeNumber(val),
    pageName: isNonEmptyStr,
    memberId: isStr,
    dest: isNonEmptyStr,
    cruiseDest: isNonEmptyStrArray,
    origin: isStr,
    locResolver: (val) => ['gaia', 'tla', 'hcom', 'cruise'].some((str) => str === val),
    dateStart: isStr,
    dateEnd: isStr,
    adults: isNonnegativeNumber,
    kids: isNonnegativeNumber,
    adProvider: isStr,
    sr: isStr
});

/**
 * Filters out invalid properties from the pushed data object
 * @param {Object} pushedAdinfoObj - The adinfo object on the targeting object pushed to the queue.
 * @returns {Object}
 */
const filterInvalidDataProps = (pushedAdinfoObj) =>
  Object.entries(pushedAdinfoObj)
    .reduce((res, [currKey, currVal]) => {
      // Include key/value pair in result if valid
      if (v2ParameterChecks.hasOwnProperty(currKey)) {
        // Check for valid data type on v2 key
        if (v2ParameterChecks[currKey](currVal)) {
          // and check if key has been passed with data
          if (currVal) {
            res[currKey] = currVal;
          }
          else {
            logger.warn(
              logEvents.TARGETING_VALUE_EMPTY,
              {
                description: `No value for key=${currKey}`,
                methodName: 'filterInvalidDataProps'
              }
            );
          }
        }
        // Otherwise, log an error
        else {
          logger.error(
            logEvents.TARGETING_INVALID,
            new Error('Invalid Targeting Data'),
            {
                description: `Invalid Parameter: key=${currKey} val=${currVal}`,
                methodName: 'filterInvalidDataProps'
            }
          );
        }
      }

      return res;
    }, {});

/**
 * Log warning if locResolver is missing when it's required.
 * Logs error if locResolver resolver is not GAIA
 * @param {Object} validatedParams
 */
function validateLocResolver(validatedParams) {
  if (validatedParams.hasOwnProperty('locResolver')) {
    if (validatedParams.locResolver !== 'gaia') {
      logger.info(logEvents.NON_GAIA_LOCRESOLVER,
        { params: {
              locResolver: validatedParams.locResolver,
              dest: validatedParams.dest,
              origin: validatedParams.origin
        } });
    }
  }
  else if (validatedParams.hasOwnProperty('dest') || validatedParams.hasOwnProperty('origin')) {
    logger.error(
      logEvents.TARGETING_INVALID,
      new Error('Invalid Targeting Data'),
      {
          description: 'Invalid Parameter: locResolver is required when dest or origin are provided.',
          methodName: 'validateLocResolver'
      }
    );
  }
}

/**
 * Filters out invalid params and checks for required props.
 * @param {Object} params
 */
function validateAdinfoParams(params = {}) {
  const validatedParams = filterInvalidDataProps(params);

  validateLocResolver(validatedParams);

  return validatedParams;
}

export {
  isStr,
  isNonEmptyStr,
  isNonEmptyStrArray,
  isNonnegativeNumber,
  filterInvalidDataProps,
  validateLocResolver,
  validateAdinfoParams
};
