import getLogger from '../utils/logger';
import { logEvents } from '../utils/logger/logEvents';

const logger = getLogger('logger');

const getDisplayAdAttributes = (element) => ({
    adProductType: 'displayad',
    source: element.path,
    refreshCount: element.refreshcount || 0
});

const getNativeAdAttributes = (element) => ({
    adProductType: 'nativead',
    source: element.adPath
});

const getCommonAttributes = (element) => ({
    slot: element.uci,
    adStack: 'ADS'
});

const listener = {
  added: false,
  handleEvent: () => {
    const activeElement = document.activeElement;
    if (activeElement.tagName === 'MESO-DISPLAY-AD') {
      logger.info(logEvents.AD_CLICK, Object.assign(getCommonAttributes(activeElement), getDisplayAdAttributes(activeElement)));
    }
    else if (activeElement.tagName === 'MESO-NATIVE') {
      logger.info(logEvents.AD_CLICK, Object.assign(getCommonAttributes(activeElement), getNativeAdAttributes(activeElement)));
    }
  }
};

const initialize = () => {
  if (!listener.added) {
    window.addEventListener('blur', listener);
    listener.added = true;
  }
};

export default {
  initialize,
  listener
};
