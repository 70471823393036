import queryString from 'query-string';
import { createPrioritizedEventQueue } from 'meso-event-queue';
import utils from '../utils';
import privacy from '../privacy/framework';
import ExperimentsFramework from './framework.js';

// Set Experiments to null until configuration is available.
let Experiments = null;

/**
 * Merges global experiments configuration file with legacy experiments configuration.
 * @param {array} configured - experiments from global configuration
 * @param {array} legacy - experiments from legacy configuration
 * @return {array} - merged and deduped experiments (prefer global)
 */
// eslint-disable-next-line
const _mergeExperimentConfigs = (configured, legacy) => {
  const configuredNames = configured.map((experiment) => experiment.name);
  const filteredLegacyExperiments = legacy.filter((experiment) => !configuredNames.includes(experiment.name));

  return configured.concat(filteredLegacyExperiments);
};

/**
 * Sets up the experiments framework based on the passed ADS response.
 * See the ./src/ads-delivery-interface.js for the request/response.
 * @param {object} response - The response returned from an ADS request.
 */
const initialize = (response) => {
  const { params, config, experiments } = response;

  // Exit if we cannot track user data.
  const canTrack = privacy.getPrivacyByKey('canTrack');
  if (!canTrack) {
    return;
  }

  // Process handles any pushes to the experiments interface.
  // @param {object} cmd - { type: string, content: {} }
  const process = (cmd) => {
    switch (cmd.type) {
      // Write experiments to the Experiments Object.
      case 'update':
        if (cmd.experiments) {
          Experiments.update(cmd.experiments);
        }
        break;
      // Clear all experiments. Experiments Object will be an empty object.
      case 'clear':
        Experiments.clear();
        break;
      // Override current experiment values with new ones.
      case 'override':
        if (
          cmd.content !== undefined &&
          typeof cmd.content === 'string'
        ) {
          utils.setCookie('meov', cmd.content);
        }
        break;
      // Set all configured experiments to control.
      case 'control':
        Experiments.control();
        break;
      default:
    }
  };

  // Process the query string and save overrides to the cookie.
  const qs = queryString.parse(utils.getLocationSearch());
  if (qs.meov !== undefined) {
    utils.setCookie('meov', qs.meov);
  }

  if (config && params) {
    // Make sure that experiments is an array.
    const configuredExperiments = Array.isArray(experiments) ? experiments : [];
    // Retrieve the legacy experiments config until we have cleaned up all instances.
    const legacyExperiments = config.EXPERIMENTS && config.EXPERIMENTS.conditions || [];
    // Merge the global experiments with the legacy experiments.
    const mergedExperiments = _mergeExperimentConfigs(configuredExperiments, legacyExperiments);

    Experiments = new ExperimentsFramework(
      mergedExperiments,
      params
    );

    window.meso = window.meso || {};
    window.meso.experiments = window.meso.experiments || [];

    // Process any commands already pushed to the interface,
    // then replace the push function with the process function.
    if (!window.meso.experiments.isEventQueue) {
      const priority = ['clear', 'update', 'override', 'control'];
      window.meso.experiments = createPrioritizedEventQueue(process, window.meso.experiments, priority, {});
    }

    // Expose the configured experiment names. This must be done AFTER the
    // event queue is created so that window.meso.experiments is an object.
    window.meso.experiments.configuredExperimentNames = Experiments.configuredExperimentNames;
    window.meso.experiments.configuredOverrideNames = Experiments.configuredOverrideNames;

    // Once all of the experiments have been processed from the queue,
    // trigger the initialize call with the framework.
    Experiments.initialize();
  }
};

export default {
  initialize
};
