const isPlainObject = require('lodash.isplainobject');

// list of all schema offending warnings
let schemaOffendingWarnings = [];

/**
 * Check if a logging field is a boolean type.
 */
const isBoolean = (field) => (typeof field === 'boolean');

/**
 * Generate a descriptive schema offending warning message and add to the warning list.
 */
const addToWarnings = (fieldName, expectedType, actualType, contentString) => {
  const warning = `Field "${fieldName}" should be ${expectedType} type but getting ${actualType} type. Field content to string: "${contentString}". Please refer "egsp-meso-frontend" repository for more information.`;
  schemaOffendingWarnings.push(warning);
};

/**
 * "siteId" field is defined as string in Avro schema.
 * According to raw logs in meso-test/raw/creativePerformance S3 bucket, siteId field is sent as number type sometimes.
 * @param parentField reference to the parent field of siteId
 * @param parentFieldName path name of the parent field in string
 */
const checkSiteIdField = (parentField, parentFieldName) => {
  const siteId = parentField.siteId;
  const currentFieldName = `${parentFieldName}.siteId`;
  if (typeof siteId !== 'string') {
    addToWarnings(currentFieldName, 'string', typeof siteId, `${siteId}`);
    parentField.siteId = `${siteId}`;
  }
};

/**
 * "isAdsBlocked" field is defined as boolean type in Avro schema.
 * According to raw logs in meso-test/raw/creativePerformance S3 bucket, isAdsBlocked field is sent as JS plain object sometimes.
 * @param parentField reference to the parent field of isAdsBlocked
 * @param parentFieldName path name of the parent field in string
 */
const checkIsAdsBlockedField = (parentField) => {
  const isAdsBlocked = parentField.isAdsBlocked;
  if (!isBoolean(isAdsBlocked)) {
    let contentString = `${isAdsBlocked}`;
    if (isPlainObject(isAdsBlocked)) {
      contentString = JSON.stringify(isAdsBlocked);
    }
    addToWarnings('logs.details.isAdsBlocked', `boolean`, typeof isAdsBlocked, contentString);
    delete parentField.isAdsBlocked;
  }
};

/**
 * "params" field is defined as object literal in Avro schema.
 * According to raw logs in meso-test/raw/creativePerformance S3 bucket, params field is sent as string type sometimes.
 * @param parentField reference to the parent field of params
 * @param parentFieldName path name of the parent field in string
 */
const checkParamsField = (parentField) => {
  const params = parentField.params;
  const fieldName = 'logs.details.params';
  if (isPlainObject(params)) {
    if (params.siteId) {
      checkSiteIdField(params, fieldName);
    }
  }
  else {
    addToWarnings(fieldName, 'plain object', typeof params, `${params}`);
    parentField.params = {};
  }
};

/**
 * "size" field is defined as an array of integers in Avro schema.
 * * According to raw logs in meso-test/raw/creativePerformance S3 bucket, the size field is sent as a string type sometimes.
 * @param parentField reference to the parent field of size
 */
const checkSizeField = (parentField) => {
  const size = parentField.size;
  if (size) {
    if (typeof size[0] === 'string' || typeof size === 'string') {
      let correctSize = size;
      if (!Array.isArray(size)) {
        correctSize = correctSize.split('x');
      }
      correctSize = correctSize.map((i) => parseInt(i, 10));
      addToWarnings('logs.details.size', 'array<int>', typeof size, size);
      parentField.size = correctSize;
    }
  }
};

/**
 * "creativeId " field is defined as a long in Avro schema.
 * According to raw logs in meso-test/raw/creativePerformance S3 bucket, creativeId field is sent as a string type sometimes.
 * @param parentField reference to the parent field of creativeId
 */
const checkCreativeIdField = (parentField) => {
  const creativeId = parentField.creativeId;
  if (typeof creativeId === 'string') {
    addToWarnings('logs.details.creativeId', 'long', typeof creativeId, creativeId);
    parentField.creativeId = parseInt(creativeId, 10);
  }
};

/**
 * Check the type of certain fields in log against pre-defined Avro schema for EGSP stream.
 * Avro schema is located in "MediaSolutions/egsp-meso-frontend" repository.
 * Add a descriptive warning message to schema offending warning list if a field has incorrect type for debugging purpose.
 * Mutate field's value with correct type so that this log could go through EGSP collector schema check
 * and landed in EGDL Hive database for further investigation.
 * @param logs a field in log message which contains most logging details
 */
const sanitize = (logs) => {
  if (logs) {
    if (logs.siteId) {
      checkSiteIdField(logs, 'logs');
    }
    if (logs.details) {
      if (logs.details.params) {
        checkParamsField(logs.details);
      }
      if (logs.details.size) {
        checkSizeField(logs.details);
      }
      if (logs.details.creativeId) {
        checkCreativeIdField(logs.details);
      }
      if (logs.details.isAdsBlocked) {
        checkIsAdsBlockedField(logs.details);
      }
    }
  }
  // warning list is not empty
  if (schemaOffendingWarnings.length) {
    logs.schemaOffendingWarnings = schemaOffendingWarnings;
    schemaOffendingWarnings = [];
  }
};

export default {
  sanitize
};
