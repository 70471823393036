/*
 * Watcher event class.
 *
 * Allows events to be registered and executed when requested.
 *
 */
class Watcher {
  constructor() {
    this._count = 0;
    this._events = {};
  }

  /*
   * Register a callback.
   *
   * Returns Destroyer of worlds - cleanup function to this event for easy extermination
   *
   * Opts:
   *  runOnce - When execute runs the registered event will fire once and then unregister itself.
   */
  add(callback, opts = {}) {
    if (typeof callback !== 'function') {
      throw new Error('Targeting notify requires a function for a callback.');
    }
    const id = this._count;
    const destroyer = () => delete this._events[id];
    this._events[id] = [callback, opts, destroyer];
    this._count += 1;

    return destroyer;
  }

  /*
   * Broadcast args to all registered events.
   */
  notify(...args) {
    Object.keys(this._events).forEach((id) => {
      const [callback, opts, destroyer] = this._events[id];
      callback(...args);
      if (opts.runOnce) {
        destroyer();
      }
    });
  }

  get events() {
    return this._events;
  }
}

export default Watcher;
