const SERVICE_DOMAIN = 'https://ads-delivery-service.rcp.sponsoredcontent.test.exp-aws.net';
const LOADER_DOMAIN = 'https://www.uciservice.com/assets/meso-loaders';

export default {
  toolkit: `${SERVICE_DOMAIN}/api/v1/toolkit`,
  resourceEndpoint: `${SERVICE_DOMAIN}/api/v1/resource`,
  fetchTimeout: 5000,
  adblock: {
    pixel: {
      url: `${LOADER_DOMAIN}/px.gif`
    }
  },
  meso: {
    storage: {
      targeting: 'com.expedia.meso.targeting'
    }
  },
  dsaService: '/sponsoredcontent/v1/ad-transparencies',
  targetingService: 'https://targeting-service.rcp.us-west-2.sponsoredcontent.test.exp-aws.net/v3/adinfo',
  experiments: {
    throttle: 200,
    abacus: {
      endpoint: '/api/bucketing/v1'
    }
  },
  logging: {
    collector: 'https://meso-event-collector.rcp.sponsoredcontent.test.exp-aws.net/events',
    performance: {
      endpoint: '/cl/data/creativePerformance.json?batch=true'
    },
    error: {
      endpoint: '/cl/data/creativePerformance.json?batch=true'
    },
    autoCallout: 60000,
    maxLogsInQueue: 15, // max 64KB allowed
    platform: 'Web'
  },
  travelPixel: {
    endpointProtocol: 'https://',
    endpointPath: '/trvl-px/v2/ppid'
  }
};
