import Watcher from './watcher';

/*
 * Observe things and register events that will fire with a common condition
 */
class Observer {
  constructor(requirement) {
    this.watchers = new Watcher();
    this.requirement = requirement;
  }

  /*
   * Check if we should notify observers based on list of changed items
   */
  requiresUpdate(changed) {
    return this.requirement(changed);
  }

  /*
   * update all watchers with specified data set
   */
  notify(data) {
    this.watchers.notify(data);
  }

  /*
   * Add a new callback to fire on notify.
   *
   * passing in the current data will check and fire the callback once depending on the requirement function
   */
  add(fn, data = {}) {
    if (this.requiresUpdate(Object.keys(data))) {
      fn(data);
    }

    return this.watchers.add(fn);
  }
}

/**
 * Create an observable object that will update observers
 */
class Observable {
  /**
   * Create a new Observable wrapping an Object
   *
   * @param {object} obj - object under observation
   */
  constructor(obj) {
    this.obj = obj || {};
    this.changelist = [];
    this._registeredObservers = {};
  }

  /**
   * Get a list of keys that have changed
   *
   * @param {Object} newObj - the object to get the diff against
   */
  diff(newObj) {
    const diff = [];
    for (const [key, value] of Object.entries(newObj)) {
      if (!(key in this.obj && this.obj[key] === value)) {
        diff.push(key);
      }
    }

    return diff;
  }

  /**
   * Update the observable with new object. This will merge objects using Object.assign
   *
   * When update detects a change it will fire all observers.
   *
   * @param {Object} obj - new object to update current object with
   */
  update(obj) {
    const changelist = this.diff(obj);
    if (changelist.length > 0) {
      Object.assign(this.obj, obj);
      const immutable = JSON.stringify(this.obj);
      Object.values(this._registeredObservers).forEach((observer) => {
        if (observer.requiresUpdate(changelist)) {
          observer.notify(JSON.parse(immutable));
        }
      });
    }
  }

  /**
   * Get the current object's data
   */
  get value() {
    return this.obj;
  }

  /**
   * Register an observer that will fire when observable is updated.
   *
   * @param {String} name - the name of the observer to group.
   * @param {function} requirement - when requirement returns true it will fire observer on update
   * @param {function} callback - callback to fire when observable is updated and requirement is true
   */
  observe(name, requirement, callback) {
    if (!(name in this._registeredObservers)) {
      this._registeredObservers[name] = new Observer(requirement);
    }

    return this._registeredObservers[name].add(callback, this.obj);
  }
}

export default {
  Observer, Observable
};
