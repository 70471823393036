import './breakpoints';
import mesoEvents from './meso-events';
import mesoInternalEvents from './meso-internal-events';
import adcontext from './adcontext';
import beacons from './beacons';
import privacy from './privacy';
import clickInterceptor from './click-interceptor';
import clickActionFrameworkLoader from './click-action-framework-loader';
import transparency from './transparency';

// Bootstrap functionality to the window/global.
export default function bootstrap() {
  // Make sure that context is available first.
  adcontext.initEvents();
  beacons.initialize();
  privacy.initialize();
  mesoEvents.initEvents();
  mesoInternalEvents.initEvents();
  clickInterceptor.initialize();
  clickActionFrameworkLoader.initialize();
  transparency.initialize();
}
