import getLogger from '../utils/logger';
import { logEvents } from '../utils/logger/logEvents';
import Breakpoint from './breakpoint';

// Make sure that adcontext and breakpoints exist.
window.meso = window.meso || {};
window.meso.breakpoints = window.meso.breakpoints || [];
const logger = getLogger('PlatformBreakpoints');
/**
 * @classdesc Class for representing all the breakpoints of a platform.
 * @class
 */
class PlatformBreakpoints {
  constructor(platforms = {}) {
    this._platforms = platforms;
    this._breakpoints = [];
  }

  /**
   * Returns the config for the specified platform or null if not found.
   * @param {string} platform - The name of the platform.
   * @returns {object|null} - The configuration if it exists, null if it does not.
   * @private
   */
  _getPlatformConfig(platform) {
    if (Object.keys(this._platforms).includes(platform)) {
      const config = this._platforms[platform];
      if (window.meso.breakpoints.isEventQueue) {
        window.meso.breakpoints.config = config;
      }
      else {
        logger.warn(logEvents.BREAKPOINTS_ERROR, {
          description: `${this.constructor.name}: window.meso.breakpoints is not an event queue as expected`,
          methodName: '_getPlatformConfig'
        });
      }

      return config;
    }

    return null;
  }

  /**
   * Set the current platform and configure the breakpoint listeners.
   * @param {string} platform - The name of the platform.
   */
  setPlatform(platform) {
    const config = this._getPlatformConfig(platform);
    if (config === null) {
      logger.warn(logEvents.BREAKPOINTS_ERROR, {
        description: `${this.constructor.name}: platform not found in known breakpoint platforms`,
        requested: [platform],
        known: Object.keys(this._platforms),
        methodName: 'setPlatform'
      });
    }
    else {
      // Remove any currently configured breakpoints.
      this._removeBreakpoints();
      this._addBreakpoints(config);
    }
  }

  /**
   * Adds breakpoints for the current platform.
   * @param {object} mediaList - The list of breakpoints from the platform config.
   * @private
   */
  _addBreakpoints(mediaList) {
    mediaList.forEach((media) => {
      const bp = new Breakpoint(media);
      bp.startListening();
      this._breakpoints.push(bp);
    });
  }

  /**
   * Stops listening on all current breakpoints and removes them.
   * @private
   */
  _removeBreakpoints() {
    this._breakpoints = this._breakpoints.filter((bp) => {
      bp.stopListening();

      return false;
    });
  }
}

export default PlatformBreakpoints;
