import { createEventQueue } from 'meso-event-queue';
import observe from '../observe';

const adcontext = new observe.Observable({});

// Callback that handles new adcontext events
// Merges into adcontext and checks registered observers if they need to be notified
const updateAdContext = (data) => {
  adcontext.update(data);
};

/**
 * Get a shallow-copy clone of adcontext.value
 *
 * @returns {Object}
 */
const getAdContext = () => Object.assign({}, adcontext.value);

// Initialize adcontext object to hook up array into observed object
const initEvents = () => {
  window.meso = window.meso || {};
  window.meso.adcontext = window.meso.adcontext || [];

  if (!window.meso.adcontext.isEventQueue) {
    window.meso.adcontext = createEventQueue(updateAdContext, window.meso.adcontext, { observable: adcontext });

    window.meso.adcontext.getAdContext = getAdContext;
  }
};

export default {
  initEvents
};
