import utils from '../utils';

const ACCEPTABLE_ADS_PHASE_2 = 'Acceptable_Ads_Phase_2';

/**
 * Adds additional parameters to the targeting object
 * @param {object} obj - the object to modify
 * @returns {object} - the modified object
 */
const addAdditionalParameters = (obj) => {
  if (!utils.isObject(obj)) {
    throw new Error('First argument must be an object');
  }
  const params = { ads: '1' }; // key of 'ads' with a value of '1' to the passed object.
  const acceptableAdsBucket = utils.getTestBucket(ACCEPTABLE_ADS_PHASE_2) || 0;
  if (window.meso.adblock?.acceptableads === true && acceptableAdsBucket === 1) {
    params.aaa = 1;
  }

  return Object.assign({}, obj, params);
};

export default {
  addAdditionalParameters
};
