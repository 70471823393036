import './handlers';
import config from '../../config';
import utils from './../index';
import sanitizer from './sanitizer';

export class Logger {
  constructor(moduleName) {
    this.moduleName = moduleName;
    this.isDebugLoggingEnabled = window.location && window.location.search && window.location.search.includes('debug=true');
  }

  static setSessionConfig(config) {
    Logger.config = Object.assign({}, Logger.config, config);
  }

  sendLogPayload(type, msg, details) {
    window.meso = window.meso || {};
    window.meso.logging = window.meso.logging || [];
    Logger.setSessionConfig(window.meso.pageInfo);

    const logs = Object.assign(
      { type, moduleName: this.moduleName },
      msg && msg.message ? msg : { message: msg },
      Logger.config,
      { details },
      utils.userAgent
    );
    sanitizer.sanitize(logs);
    window.meso.logging.push({ logs, platform: config.logging.platform, timestamp: utils.esDateString(), epoch: Date.now() });
  }

  info(msg, details) {
    this.sendLogPayload('info', msg, details);
  }

  /**
   * Log "warn" type logging message.
   * @param logEvent an enum constant object from logEvents.js
   * @param details JS plain object
   */
  warn(logEvent, details) {
    this.sendLogPayload('warn', logEvent, details);
  }

  /**
   * Log "error" type logging message.
   * @param logEvent an enum constant object from logEvents.js
   * @param error JS built-in Error object
   * @param details JS plain object
   */
  error(logEvent, error, details) {
    const exception = error instanceof Error || typeof error === 'object' ? { message: error.message } : { message: `${error}` };
    this.sendLogPayload('error', logEvent, Object.assign({ exception }, details));
  }

  debug(msg, details) {
    if (this.isDebugLoggingEnabled) {
      // eslint-disable-next-line
      console.info(`${performance.now()} ${this.moduleName}:${msg}`, details || {});
    }
  }

  trackEvent(name, vars, events) {
    this.sendLogPayload('track-event', { name, vars, events });
  }

  trackAction(name, vars, events) {
    this.sendLogPayload('track-action', { name, vars, events });
  }

  perf(msg, options) {
    if (
      options &&
      options.mark &&
      performance.getEntriesByName(options.mark).length > 0
    ) {
      const { mark } = options;
      performance.measure('Performance Measure', mark);
      options.duration = performance
        .getEntriesByType('measure')[0].duration;
      performance.clearMeasures();
      delete options.mark;
    }
    this.sendLogPayload('perf', msg, options);
  }
}

const logCache = {};

export default (moduleName) => {
  if (!logCache[moduleName]) {
    logCache[moduleName] = new Logger(moduleName);
  }

  return logCache[moduleName];
};
