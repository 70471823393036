import ppidLoader from '../ppid-loader/ppid-loader';
import { init } from '../index';
import * as acceptableAds from '../adblock/acceptable-ads';

import utils from '../utils';
import getLogger from '../utils/logger';
import { logEvents } from '../utils/logger/logEvents';
const logger = getLogger('bernie-loader');

const adblockDetect = () => {
  // Acceptable Ads Experiment
  if (!utils.isSafeframe()) { // Do not run this in a safeframe.
    const domain = new URL(utils.getLocationHref());
    const details = { host: domain.host };

    // Log the experiment count.
    logger.info(logEvents.ADBLOCK_EXPERIMENT, details);
    acceptableAds.detect()
      .then((adblockSettings) => {
        /* istanbul ignore if */ // TODO: Ignore this for now until module stubbing is resolved.
        if (adblockSettings.enabled) {
          // Log the adblock enabled count.
          logger.info(logEvents.ADBLOCK_ENABLED, details);
        }
        /* istanbul ignore if */ // TODO: Ignore this for now unitl module stubbing is resolved.
        if (adblockSettings.acceptableads) {
          // Log the acceptable ad user count.
          logger.info(logEvents.ADBLOCK_ACCEPTABLE_ADS, details);
        }
      });
  }
};

const initBernie = () => ppidLoader.getPpid().then(() => {
  init();
}).catch((error) => {
  logger.error(logEvents.PPID_NOT_FOUND, new Error(`PPID process failed: ${JSON.stringify(error)}`));
  init();
});

(() => {
  adblockDetect();
  initBernie();
})();

export default {
  adblockDetect,
  initBernie
};
