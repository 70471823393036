import utils from '../utils';
import { sendBeacon } from './beacons';
import { createEventQueue } from 'meso-event-queue';

/**
 * Process any beacon pushed.
 * @param {object} payload - The beacon data sent.
 */
const process = (payload) => {
  if (payload.type === 'beacon') {
    const { url, data } = payload;
    sendBeacon(url, data);
  }
};

const initialize = () => {
  // Bootstrap to the window object.
  if (utils.getWindow()) {
    window.meso = window.meso || {};
    window.meso.beacons = window.meso.beacons || [];
    if (!window.meso.beacons.isEventQueue) {
      createEventQueue(process, window.meso.beacons, {}, 'beacons');
    }
  }
};

export default {
  initialize
};
