/**
 * @framework PrivacyFramework
 */
// Default values for the cache.
import defaults from './defaults';

// Module scoped cache.
let cache = Object.assign({}, defaults);

/**
 * Returns a copy of the current privacy cache.
 * @returns {object} - the current privacy cache
 */
const getPrivacy = () => Object.assign({}, cache);

/**
 * Retrieve the privacy value from the cache.
 * @param {key} - the key of the privacy value to retrieve.
 * @returns {any} the value of the privacy key
 * @throws {Error} if the passed key is not a known privacy key
 */
const getPrivacyByKey = (key) => {
  const cache = getPrivacy();

  if (cache.hasOwnProperty(key)) {
    return cache[key];
  }

  throw new Error(`${key} is not a recognized privacy key`);
};

/**
 * Sets a particular privacy metric.
 * @param {key} - The key of the privacy metric to set.
 * @param {value} - The value of the privacy metric.
 * @throws {Error} if key is not founcd in the known privacy keys
 */
const setPrivacy = (key, value) => {
  if (cache.hasOwnProperty(key)) {
    cache[key] = value;

    return;
  }

  throw new Error(`${key} is not a recognized privacy key`);
};

/**
 * Resets the cache back to defaults (used for unit tests).
 */
const reset = () => {
  cache = Object.assign({}, defaults);
};

export default {
  reset,
  getPrivacy,
  getPrivacyByKey,
  setPrivacy
};
