import merge from 'deepmerge';
import baseConfiguration from './env/all';

import prod from './env/prod';
import int from './env/int';
import dev from './env/dev';

// Following is an optimization which allows webpack to only package one config object
let config;
if (process.env.NODE_ENV === 'production') {
  config = prod;
}
else if (process.env.NODE_ENV === 'integration') {
  config = int;
}
else {
  config = dev;
}

if (process.env.MD_SERVICE) {
  config.toolkit = process.env.MD_SERVICE;
}

const mergedConfigs = merge(baseConfiguration, config);

export default mergedConfigs;
