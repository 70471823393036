import api from './ads-delivery-interface';
import { createPrioritizedEventQueue } from 'meso-event-queue';
import { fireDFPAudiencePixel } from './utils/dfpAudiencePixel';
import logging from './logging';
import observe from './observe';
import privacy from './privacy/framework';
import getLogger, { Logger } from './utils/logger';
import { logEvents } from './utils/logger/logEvents';

const initData = new observe.Observable({});
const logger = getLogger('meso-events');

// Clear the ADS cache and dispatch mesoClear
function clearAds() {
  api.clearCache();
  window.dispatchEvent(
    new CustomEvent('mesoClear')
  );
}

// Render ads from the current ADS cache.
function renderAds() {
  api.renderAds();
}

// Get configuration from ADS service and render ads.
function fetchAdsAndRender(data) {
  if (data.hasOwnProperty('canTrack')) {
    privacy.setPrivacy('canTrack', data.canTrack);
    // TODO: Remove this set after we confirm no code is using it.
    if (window && window.meso && window.meso.privacy) {
      window.meso.privacy.canTrack = data.canTrack;
    }
    // TODO: END
    logger.info('Initialized target received', {
      methodName: 'fetchAdsAndRender'
    });
  }

  if (data.hasOwnProperty('canSell')) {
    privacy.setPrivacy('canSell', data.canSell);
  }

  if (data.pageId && data.siteId && data.locale) {
    const target = {
      siteId: data.siteId,
      pageName: data.pageId,
      locale: data.locale,
      variant: data.variant || 'default',
      brand: data.brand || 'default'
    };
    Logger.setSessionConfig({
      siteId: data.siteId,
      pageId: data.pageId,
      variant: target.variant,
      locale: data.locale,
      brand: target.brand
    });
    const isAdsBlocked = window && window.meso && (window.meso.adblock === undefined || window.meso.adblock.enabled !== false);
    logger.info('AdsBlock Status', { isAdsBlocked });
    window.meso.adcontext = window.meso.adcontext || [];
    window.meso.adcontext.push(target);
    initData.update(data);
    api.fetchAds(data);
  }
}

// Callback function to be attached to window.meso.intialize. Handles multiple
// types:
// * target - Fetches ads from ADS (Ads Delivery Service) and renders them.
// * render - Renders ads from the current ADS response cache.
// * clear - Clears the current ADS response cache.
// * logger - initialize and connect the logger interface
function initialize(data) {
  if (data !== null && typeof data === 'object') {
    if (data.isInDemo) {
      data = {
        type: 'target',
        siteId: 'Meso_Docsite',
        pageId: 'page.Meso.ADS.Test',
        locale: 'en_US',
        variant: 'demo',
        canTrack: true
      };
    }
    if (data.siteId) {
      data.siteId = `${data.siteId}`;
    }
    switch (data.type) {
      case 'target':
        clearAds();
        logging.initialize(null);
        fetchAdsAndRender(data);
        fireDFPAudiencePixel(data);
        break;
      case 'render':
        renderAds();
        break;
      case 'clear':
        clearAds();
        break;
      case 'logger':
        logging.initialize(data.logger || null);
        break;
      default:
        // meso.initialize item is missing 'type' key or meso.initialize.type is invalid (not in the priority list)
        Logger.setSessionConfig({
          siteId: data.siteId,
          pageId: data.pageId,
          locale: data.locale
        });
        logger.warn(logEvents.INITIALIZE_EVENT_INVALID, {
          description: data.type ?
            `${data.type} is invalid initialize type` :
            `'type' key is absent from the targeting payload.`,
          methodName: 'initialize'
        });
        logging.initialize(null);
        fetchAdsAndRender(data);
        fireDFPAudiencePixel(data);
    }
  }
}

function initEvents() {
  window.meso = window.meso || {};
  window.meso.initialize = window.meso.initialize || [];

  if (!window.meso.initialize.isEventQueue) {
    const priority = ['clear', 'logger', 'target', 'render'];
    // For backwards compatibility, make sure that each item in the queue has a type.
    window.meso.initialize.forEach((item) => {
      if (item !== null && typeof item === 'object') {
        item.type = item.type || 'target'; // Default to target type.
      }
    });

    // initializes the pageInfo
    if (window.initialize && window.initialize.length) {
      const { siteId, pageId, locale, variant } = window.meso.initialize.find((item) => item.type === 'target');
      window.meso.pageInfo = { siteId, pageId, locale, variant };
    }

    window.meso.initialize = createPrioritizedEventQueue(
      initialize,
      window.meso.initialize,
      priority,
      { limit: 1, observable: initData }
    );
  }
}

export default {
  initEvents
};
