import getLogger from '../utils/logger';
import { logEvents } from '../utils/logger/logEvents';

// Make sure that adcontext and breakpoints exist.
window.meso = window.meso || {};
window.meso.adcontext = window.meso.adcontext || [];
window.meso.breakpoints = window.meso.breakpoints || [];
const logger = getLogger('Breakpoint');
/**
 * @classdesc Class for representing a platform breakpoint.
 * @class
 */
class Breakpoint {
  constructor(media) {
    this._mq = window.matchMedia(this._createMediaQuery(...media.range));
    this._breakpoint = media.name;
    // Create a reference for the callback for removal.
    this._callback = this._mediaQueryCallback.bind(this);
  }

  /**
   * Creates a formatted mediaquery string
   * @param {number} minWidth - minimum width of the screen for the media query match
   * @param {number} maxWidth - maximum width of the screen for the media query match
   * @private
   */
  _createMediaQuery(minWidth, maxWidth) {
    return `(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
  }

  /**
   * Sets the name of the current breakpoint on a media query change event.
   * @private
   */
  _mediaQueryCallback() {
    if (this._mq.matches) {
      if (window.meso.breakpoints.isEventQueue) {
        window.meso.breakpoints.current = this._breakpoint;
        window.meso.adcontext.push({ breakpoint: this._breakpoint });
      }
      else {
        logger.warn(logEvents.BREAKPOINTS_ERROR, {
          description: `${this.constructor.name}: window.meso.breakpoints is not an event queue as expected`,
          methodName: '_mediaQueryCallback'
        });
      }
    }
  }

  /**
   * Sets up the MediaQueryList listener to execute the callback.
   */
  startListening() {
    this._mq.addListener(this._callback);
    this._callback();
  }
  /**
   * Removes the listener.
   */
  stopListening() {
    this._mq.removeListener(this._callback);
  }
}

export default Breakpoint;
