import utils from './utils';

// Private handlers for all meso specific events.
const events = [
  'mesoRender'
];

const initEvents = () => {
  if (utils.getWindow()) {
    // Register the events.
    events.forEach((name) => {
      // eslint-disable-next-line no-unused-vars
      const evt = new CustomEvent(name);
    });
  }
};

export default {
  initEvents
};
