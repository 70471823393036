/**
 * Defer promise wrapper in order to resolve from outside.
 * @returns {object} - promise wrapper
 * {
 *  {Promise} promise - promise to resolve or reject
 *  {function} resolve - function to resolve promise
 *  {function} reject - function to reject promise
 *  {boolean} isResolved - true if Promise has been resolved, false if not
 * }
 */
const defer = () => {
  const deferred = {
          promise: null,
          resolve: null,
          reject: null,
          isResolved: false
  };

  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = (res) => {
      deferred.isResolved = true;
      resolve(res);
    };
    deferred.reject = reject;
  });

  return deferred;
};

export default {
    defer
};
