import utils from '../utils';
import config from '../config';
import { createEventQueue } from 'meso-event-queue';
import getLogger from './../utils/logger';
import { logEvents } from './../utils/logger/logEvents';

const logger = getLogger('transparency');

const DSA_ENDPOINT = `${config.dsaService}`;

/**
 * Process any transparency publish to DSA.
 * @param {object} payload - The transparency data sent.
 */
const processDSARequest = (payload) => {
  if (payload) {
    const start = performance.now();

    utils.promiseFetch(DSA_ENDPOINT, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload.data)
    }).then(() => {
      logger.info(logEvents.TRANSPARENCY_PUBLISH_REQUEST_PASSED, {
          methodName: 'processDSARequest',
          duration: performance.now() - start
      });
    })
      .catch((error) => {
        logger.error(logEvents.TRANSPARENCY_PUBLISH_REQUEST_FAILED, error, {
          description: 'Error Publishing Request to DSA',
          methodName: 'processDSARequest'
        });
      });
  }
  else {
    logger.error(logEvents.TRANSPARENCY_PUBLISH_CALLED_WITH_NO_PAYLOAD, 'Error Pushing to transparency with no payload', {
      methodName: 'processDSARequest'
    });
  }
};

const initialize = () => {
  // Bootstrap to the window object.
  if (utils.getWindow()) {
    window.meso = window.meso || {};
    window.meso.transparencies = window.meso.transparencies || [];
    if (!window.meso.transparencies.isEventQueue) {
      createEventQueue(
        processDSARequest,
        window.meso.transparencies,
        {},
        'transparencies'
      );
    }
  }
};

export default {
  initialize
};
