import bootstrap from './bootstrap';
import 'intersection-observer';
import getLogger from './utils/logger';
import { logEvents } from './utils/logger/logEvents';
import utils from './utils';

const logger = getLogger('logger');

const init = () => {
  // Checking to see if URL contains Email, stopping the bootstrap and other frameworks from loading if it does
  if (utils.checkEmail(utils.getLocationHref())) {
    logger.error(logEvents.EMAIL_FOUND_IN_URL, new Error('Email included in URL'), { methodName: 'checkEmail' });

    return;
  }

  bootstrap();
};

(() => {
  init();
})();

export {
  init
};
